.container {
  position: relative;
  min-height: 500px !important;
  padding: 35px 25px !important;
  transition: min-height 0.5s ease-in;
}

:global(.mobile) .container {
  height: 100%;
  justify-content: center;
}

:global(.mobile) .container button {
  margin-top: 50px;
}

.video {
  margin-top: 15px;
}

.video video {
  border-radius: 16px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  width: 100%;
  max-width: 600px;
}

:global(.mobile) .video video {
  width: 100%;
}

.description {
  max-width: 600px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  text-align: center;
  color: var(--neutral-colors-black);
  margin: 24px auto;
}

@media only screen and (min-width: 992px) {
  .cf-videoLesson .container {
    width: 50vw;
  }
}
