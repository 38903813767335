.toolbar {
  position: fixed;
  z-index: 1001;
  top: 19px;
  left: 30px;
}

.toolbar span {
  font-family: 'Lato';
  font-size: 20px;
  font-weight: bold;
  color: #FB5014;
}

.toolbar img {
  margin-bottom: 7px;
  margin-right: 18px;
  cursor: pointer;
}

.container {
}

.introMessage {
font-size: 13px;
line-height: 1.54;
letter-spacing: normal;
color: var(--neutral-colors-black);
text-align: justify;
}

.tipMessage {
display: block;
width: 100%;
font-size: 13px;
line-height: 1.54;
letter-spacing: normal;
color: var(--neutral-colors-black);
text-align: justify;
margin: 15px auto;
}

.content {
  max-width: 1110px;
  position: relative;
  margin: 0 auto;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  /* text-align: center; */
}

.title {
  font-family: 'Lato';
  font-size: 30px;
  font-weight: bold;
  color: #F86969;
}

.body {
}

.container{
  max-width: 816px;
  margin: 0 auto 60px;
  padding: 50px 83px 20px 82px;
  border-radius: 16px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--neutral-colors-white);
}



:global(.mobile).container{
  width: 100%;
  padding: 10vw;
  box-shadow: 0 0;
}

.btnHolder{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

.result {

}

.result .imageResult {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
:global(.mobile) .result .imageResult {
  flex-direction: column;
}

.imageResult .image {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 24px;
  width: 49%;
  height: 88px;
  border-radius: 16px;
  border: solid 1px var(--neutral-colors-gray-10);
  overflow: hidden;
}
:global(.mobile) .imageResult .image {
  width: 100%;
}

.imageResult .image .imageBox {
  height: inherit;
  border-radius: 10px;
  text-align: center;
  display: flex;
}

.imageResult .image .imageBox img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.imageResult .image.noAudio .imageBox {
  width: 650px;
  margin-right: unset;
}

.result > span {
  display: inline-block;
  margin-top: 24px;
}

.result .audioResult {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.audioResult .audio {
  position: relative;
  max-width: 450px;
  margin: 30px auto 0;
  width: 100%;
}

.audioResult .audio .player > div {
  width: 100%;
}

.audioResult .audio .bot {
  width: 80px !important;
  height: 80px;
  background-color: #FFD00D;
  border: 7px solid #EFEFEF;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  top: calc(50% - 40px);
  left: 15px;
}

:global(.mobile) .audioResult .audio .bot {
  width: 60px !important;
  height: 60px;
  border: 4px solid #EFEFEF;
  margin-top: 10px;
}

.audioResult .audio .bot img {
  width: 100%;
}

.audioResult .audio .player {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 17px;
  background-color: #fff;
  border: solid 1px var(--neutral-colors-gray-10);
  padding: 5px 20px 5px 95px;
}


@media only screen and (max-width: 800px) {
  /* .toolbar {
      display: none;
  }
  .content {
      width: 302px;
  }

  .title {
      font-size: 17px;
  }

  .body {
      line-height: 20px;
      margin-top: 20px;
      margin-bottom: 32px;
  
      font-size: 15px;
  }

  .imageResult .image {
      margin-top: 24px;
  }

  .imageResult .image .imageBox {
      width: 211px;
      height: 97px;
      margin-right: 38px;
  }

  .imageResult .image .audioPlayer img {
      width: 50px;
  }

  .imageResult .image.noAudio .imageBox {
      width: 304px;
      height: unset;
      margin-right: unset;
  }

  .result > span {
      margin-top: 17px;
  }

  .audioResult .audio {
      margin-top: 10px;
  }
  
  .audioResult .audio .bot {
      width: 57px;
      height: 57px;
      margin-left: -19px;
      margin-bottom: -39px;
  }

  .audioResult .audio .bot img {
      width: 47px;
  }
  
  .audioResult .audio .player {
      height: 53px;
      border-radius: 11px;
      padding-left: 53px;
      padding-right: 18px;
  } */
}