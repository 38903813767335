.container {
  width: 100%;
  height: 96px;
  background-color: #fff;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  border: 0px solid #fb5014;
  border-radius: 50px 10px 10px 50px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  position: relative;
  cursor: pointer;
  /* max-width: 650px !important; */
  margin: 12px 0;
}
:global(.mobile) .container {
  padding-right: 63px;
}
.scoreProgress {
  transform: rotate(45deg);
  position: absolute;
  left: 0;
  top: 0;
}
.scoreProgress .line {
  stroke-dasharray: 238;
}
.scoreProgress .background {
  fill: var(--neutral-colors-gray-10);
}
.coachScore {
  width: 28px;
  height: 28px;
  border: solid 4px var(--neutral-colors-white);
  background-image: linear-gradient(to bottom, #ffda19, #ffb20b);
  border-radius: 50px;
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 26px;
  padding-top: 2px;
  color: var(--neutral-colors-black);
}
.icon {
  align-self: center;
  margin: 0 15px 0 10px;
  position: relative;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
:global(.mobile) .icon {
  margin: 0 5px 0 10px;
}

.icon img {
  /* width: 45px;
  height: 45px; */
  width: 60px;
  height: 60px;
  position: relative;
  z-index: 1;
}

.content {
  width: 50%;
  align-self: center;
  font-family: 'Open Sans';
  font-weight: bold;
  line-height: 24px;
}
:global(.mobile) .content {
  width: 60%;
}

.title {
  display: block;
  font-size: 16px;
  font-weight: 500;
}

.subtitle {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: var(--neutral-colors-gray-40);
  line-height: 1.2;
  margin-top: 5px;
}

.rightSide {
  height: 50%;
  position: absolute;
  right: 0;
  top: 25%;
  display: flex;
  align-items: center;
  background-color: var(--chatter-fox);
  border-radius: 50px 0 0 50px;
  padding: 0 15px 0 12px;
}

.rightSide.withoutBackground {
  background-color: unset;
}

:global(.mobile) .rightSide {
  padding: 0 7px 0 12px;
}
:global(.mobile) .rightSide .rightArrow {
  width: 7px;
  background-position: 51px 0;
  height: 7px;
}

.rightSide img {
  width: 40px;
}

.rightSide span {
  text-align: right;
  color: #fff;
  font-size: 11px;
  font-weight: 300;
  margin-right: 2px;
  line-height: 1.2;
}

.rightSide .scoreNumber {
  font-size: 16px;
  font-weight: 400;
}

.rightArrow {
  display: block;
  border: 1px solid #fff;
  border-width: 2px 2px 0 0;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  position: relative;
  transition-duration: 0.3s;
}

.rightSide:hover .rightArrow {
  transform: translateX(7px) rotate(45deg);
}

.scoreHolder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.score {
  position: relative;
  width: 50px;
  height: 68px;
  color: #fff;
  text-align: center;
  padding: 5px;
  box-sizing: border-box;
  margin-right: 17px;
  padding-top: 5px;
}

.score:after {
  content: '';
  position: absolute;
  bottom: -0.1px;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 25px 15px 25px;
  border-color: transparent transparent #ffffff transparent;
}

.score span {
  position: relative;
  line-height: 11px;
  font-family: 'Arial';
  font-size: 14px;
}

.feedback {
  width: 139px;
  height: 30px;
  padding: 5px;
  background-color: #e5e5e5;
  font-family: 'Arial';
  font-size: 15px;
  color: #adadac;
  position: absolute;
  right: 0;
  bottom: 0;
  border: 0 solid;
  border-radius: 5px 0 0 0;
}

.available {
  background: linear-gradient(135deg, rgba(243, 101, 79, 1) 0%, rgba(252, 163, 81, 1) 100%);
  color: #ffffff;
}

/* @media only screen and (max-width: 650px) {
  .rightSide span {
    display: none;
  }
} */
/* @media only screen and (max-width: 800px) {
    .container {
        width: 290px;
        height: 110px;
        border-radius: 11px;
    }
    
    .icon {
    }
    
    .icon img {
        width: 57px;
        height: 57px;
    }
    
    .content {
    }
    
    .title {
        font-family: 'Lato';
        font-size: 16px;
    }
    
    .subtitle {
        font-family: 'Arial';
        font-size: 14px;
        font-weight: normal;
        color: #767674;
    }
    
    .rightSide {
    }

    .score {
        width: 42px;
        height: 62px;
        padding: 3px;
        margin-right: 17px;
        padding-top: 5px;
    }
    
    .score:after {
       border-width: 0 21px 12px 21px;
    }
    
    .score span {
        font-size: 12px;
    }
    
    .feedback {
        width: 135px;
        font-size: 14px;
    }
} */
