.container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 0 16px 20px;
}

.container::before {
  content: '';

  position: absolute;
  left: 0;
  top: 12px;

  width: 9px;
  height: 72px;
  border-radius: 0 4.5px 4.5px 0;
  background-color: var(--neutral-colors-gray-10);
}

.container::after {
  font-family: FontAwesome;
  content: '\f054';
  position: absolute;
  right: 0;
  top: 34px;
  color: var(--neutral-colors-gray-50);
}

.header {
  display: flex;
  flex-direction: row;
}

.header .text {
  font-size: 15px;
  font-weight: 300;
  line-height: 1.6;
  color: var(--neutral-colors-black);
}

.header .icon {
  width: 24px;
  height: 24px;
}

.body {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
}

.body .icon {
  width: 47px;
  height: 47px;
  margin-right: 19px;
}

.body .content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content .title {
  font-family: 'Lato';
  font-weight: bold;
  font-size: 18px;
  white-space: normal;
}

.content .subtitle {
  font-size: 13px;
  font-weight: 300;
  line-height: 1.23;
  color: var(--neutral-colors-gray-50);
}

.body .date {
  font-size: 13px;
  font-weight: 300;
  line-height: 1.23;
  color: var(--neutral-colors-gray-50);
  width: 80px;
  text-align: right;
  align-self: end;
}

.unread.container::before {
  background-color: var(--chatter-fox);
}

.unread .header .text {
  color: #000;
}

@media only screen and (max-width: 800px) {
  .container {
    height: 110px;
    padding: 15px 21px;
  }

  .header {
    display: flex;
    flex-direction: row;
  }

  .header .text {
    font-size: 16px;
    margin-right: 10px;
  }

  .header .icon {
    width: 16px;
    height: 16px;
  }

  .body {
  }

  .body .icon {
    width: 35px;
    height: 35px;
    margin-right: 8px;
  }

  .body .content {
  }

  .content .title {
    font-size: 16px;
  }

  .content .subtitle {
    font-size: 14px;
  }

  .body .date {
    font-size: 14px;
    width: 60px;
  }
}
