.modal-dialog.defaultModal {
    width: 700px;
    border-radius: 30px;
    max-width: none;
}
.modal-dialog .modal-content{
    border-radius: 30px;
}

.initial-assessment .modal-dialog {
    width: 50vw;
    max-width: unset;
    margin: 0 auto;
}

.initial-assessment .modal-content {
    border-radius: 30px;
    padding: 1vw 2vw !important;
}

.initial-assessment .modal-content.pb-2 {
}

.initial-assessment .modal-body {
}

.initial-assessment .modal-body h4 {
    font-family: 'Lato';
    font-weight: bold;
    font-size: 25px;
    color: #000;

    margin-bottom: 35px;
}

.initial-assessment .modal-body p {
    font-family: 'Lato';
    font-weight: normal;
    font-size: 20px;
    color: #000;
    line-height: 35px;
}

.initial-assessment .modal-footer {
    justify-content: flex-end;
    border-top: unset;
}

.initial-assessment .btn {
    padding: 0;
    border: none;
    background: none;
    margin-right: unset;


    font-family: 'Lato';
    font-weight: bold;
    font-size: 25px;
}

.initial-assessment .btn-primary {
    color: #F86969;
}

.initial-assessment .btn-primary:hover {
    color: #8F3C3C;
}

.initial-assessment .btn-secondary {
    color: #AFAFAF;
    margin-right: 56px;
}

.initial-assessment .btn-secondary:hover {
    color: #5A5959;
}

@media only screen and (max-width: 800px) {
    .initial-assessment .modal-dialog {
        width: 302px;
    }
    
    .initial-assessment .modal-content {
        border-radius: 8px;
        padding: 24px 28px !important;
    }

    .initial-assessment .modal-body {
        padding: unset;
    }

    .initial-assessment .modal-body h4 {
        font-size: 16px !important;
    }
    
    .initial-assessment .modal-body p {
        font-size: 14px;
        margin-top: 19px;   
        line-height: 22px;
    }

    .initial-assessment .modal-footer {
        margin-top: 16px;
        padding: unset;
    }

    .initial-assessment .btn-secondary {
        margin-top: unset;
        margin-right: 20px;
    }
}