.section{
    background: url('../../assets/images/yellowCircleGradient.svg') no-repeat;
    background-position-y: 100%;
    background-position-x: center;
    background-size: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 20px;
    align-items: center;
}
:global(.mobile) .section{
    padding: 0 0px;
    background-size: auto;
}
.hide{
    display: none;
}
.bold{
    font-weight: 600;
}
.logo{
    width: 50px;
}
:global(.mobile) .logo{
    display: none;
}
.header{
    width: 700px;
    margin: 15px auto;
    display: flex;
    align-items: center;
    display: none;
}
:global(.mobile) .header{
    width: 100%;
    padding-top: 20px;
}
.waveLine{
    width: 100%;
    position: absolute;
    bottom: 20px;
    z-index: -1;
    opacity: .5;
}
.waveLine img{
    width: 100%;
}
.questionBox{
    /* border: 2px solid rgba(0,0,0,.5); */
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 25px;
    padding: 40px 60px;
    max-width: 700px;
    margin: 20px auto;
    position: relative;
    background-color: #fff;
    box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.05);
    transition-duration: .3s;
}
.questionBox.withHeader{
    padding-top: 90px;
}
.questionBox .questionSubtitle{
    font-size: 16px;
    display: block;
    margin-bottom: 30px;
    margin-top: 5px;
}
:global(.mobile) .questionBox{
    padding: 30px 20px 20px;
    margin: 40px auto 20px;
}
:global(.mobile) .questionBox.withHeader{
    padding: 70px 20px 20px;
}
.questionBox :global(.form-select) {
    box-shadow: 0 0 !important;
    font-size: 20px;
    margin-left: 45px;
    width: 350px !important;
    max-width: 100%;
}
:global(.mobile) .questionBox :global(.form-select) {
    font-size: 16px;
    margin-left: 0px;
}
.questionBoxBorder{
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid #F5A153;
    border-radius: 20px;
    z-index: -1;
    left: 16px;
    top: 14px;
}
:global(.mobile) .questionBoxBorder{
    top: -10px;
    left: 3.5%;
    width: 93%;
    height: calc(100% + 20px);
}
.infoBtn{
    margin: 0 15px; 
}
.question{
    font-size: 26px;
    font-weight: 600;
    line-height: 1.2;
}
:global(.mobile) .question{
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2;
}
.info{
    font-size: 20px;
    margin: 15px 0;
    padding-left: 10px;
    display: flex;
    cursor: pointer;
}
:global(.mobile) .info{
    font-size: 16px;
    padding-left: 0;
}
.selectBoxHolder{
    width: 30px;
    margin-right: 7px;
    margin-top: 4px;
}
:global(.mobile) .selectBoxHolder{
    width: 22px;
}
.selectBox{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 20px;
    height: 20px;
    border: 2px solid;
    margin-right: 20px;
    border-radius: 50px;
    flex: 0 0 auto;
    margin-bottom: auto;
    /* animation: unselectAnimation; */
    animation-duration: .3s;
    animation-timing-function: cubic-bezier();
}

.multiChoice .selectBox{
    border-radius: 0;
}

.selected .selectBox{
    border-color: rgb(0, 131, 11); 
}
.selected .selectBox::before{
    content: "";
    background-color: rgb(0, 131, 11); 
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50px;

}
.multiChoice .selected .selectBox:before{
    display: none !important; 
}
.multiChoice .selected .selectBox{
    width: 10px;
    height: 20px;
    border-color: rgb(0, 131, 11); 
    border-width: 4px;
    border-left-width: 0;
    border-top-width: 0;
    /* animation: selectAnimation; */
    animation-duration: .3s;
    animation-timing-function: cubic-bezier();
    transform: rotate(45deg);
    border-radius: 0px;
    margin-right: 24px;
    margin-left: 5px;
}

:global(.mobile) .selectBox{
    scale: .8;
}

@keyframes selectAnimation {
    from {
        width: 20px;
        border: 2px solid #000;
        border-left-width: 2px;
        border-top-width: 2px;
        transform: rotate(0deg);
        border-radius: 50px;
    }
    to {
        width: 10px;
        border-width: 4px;
        border-color: rgb(0, 131, 11); 
        border-left-width: 2px;
        border-left-color: transparent;
        border-top-color: transparent;
        border-top-width: 2px;
        transform: rotate(45deg);
        border-radius: 0px;
        margin-right: 24px;
        margin-left: 5px;
    }
  }
@keyframes unselectAnimation {
    from {
        width: 10px;
        border-width: 4px;
        border-color: rgb(0, 131, 11); 
        border-left-width: 2px;
        border-left-color: transparent;
        border-top-color: transparent;
        border-top-width: 2px;
        transform: rotate(45deg);
        border-radius: 0px;
        margin-right: 24px;
        margin-left: 5px;
    }
    to {
        width: 20px;
        border: 2px solid #000;
        border-left-width: 2px;
        border-top-width: 2px;
        transform: rotate(0deg);
        border-radius: 50px;
    }
  }

  .btnHolder{
      display: flex;
      justify-content: center;
      margin: 60px 0;
  }
  :global(.mobile) .btnHolder{
      margin: 40px;
  }

  .progressBar{
      position: relative;
      width: 300px;
      display: flex;
      align-items: center;
      margin-left: auto;
      /* background-color: #fff; */
      padding: 12px;
      border-radius: 50px;
  }
  :global(.mobile) .progressBar{
      margin: auto;
  }
  .progressTest{
      width: 100%;
  }
  :global(.mobile) .progressTest{
      max-width: 230px;
      margin: auto;
  }
  .progressLine{
      display: block;
      width: 100%;
      height: 3px;
      background-color: #FF8E01;
      border-radius: 10px;
    }
.breakPoint{
    display: block;
    width: 20px;
    height: 20px;
    background-color: #FFF;
    border: 2px solid #FF8E01;
    border-radius: 50px;
    position: absolute;
}
.breakPoint.bp1{
    left: 1%;
}
.breakPoint.bp2{
    left: 33%;
}
.breakPoint.bp3{
    left: 66%;
}
.breakPoint.bp4{
    left: 94%;
}
.infoTitleBox{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
}
.infoTitleBox h2{
    font-size: 26px;
    font-weight: 600;
    white-space: nowrap;
    color: #fff;
    margin: 0;
    border-radius: 25px 25px 0 0;
    padding: 15px 25px;
    width: 100%;
    text-align: center;
    background-color: #FF8E01;
}
:global(.mobile) .infoTitleBox h2{
    font-size: 20px !important;
    padding: 14px 10px;
    white-space: normal;
}
.infoTitleBox .sideLine{
    display: none;
    background-color: #FF8E01;
    width: 100%;
    height: 2px;
    /* flex: 0 0 auto; */
}
.infoDescription,
.infoDescription p{
    /* margin-top: 40px; */
    font-size: 18px;
    color: #000;
    font-weight: 300;
    line-height: 1.4;
}
.infoDescription h3{
    color: #000;
}
.infoDescription ul{
    margin: 10px 0 0;
}
.infoDescription ul li{
    list-style: disc;
}
.infoDescription :global(.noomDesHighlight){
    font-weight: bold;
    color: #FF8E01;
}
.noTitle .infoDescription{
    margin-top: 10px;
}
.infoDescription :global(.slideBtnHolder){
    width: 100%;
    display: flex;
    justify-content: space-around;
}
:global(.mobile) .infoDescription :global(.slideBtnHolder){
    flex-wrap: wrap;
}
.infoDescription :global(.slideBtnHolder) a, 
.noomBtn{
    width: 23%;
    height: 60px;
    background-color: #F5A153;
    border: 0 solid;
    border-radius: 15px;
    color: #fff !important;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    transition: all .2s ease-in-out;
    position: relative;
    cursor: pointer;
}
.infoDescription :global(.slideBtnHolder) a :global(.subtitle),
.btnSubtitle{
    position: absolute;
    top: 111%;
    width: 100%;
    font-size: 12px;
    color: #565656;
    text-align: center;
}
:global(.mobile) .infoDescription :global(.slideBtnHolder) a,
:global(.mobile) .noomBtn{
    min-width: 45%;
    margin-bottom: 20px;
    font-size: 18px !important;
}
:global(.mobile) .infoDescription :global(.slideBtnHolder) a :global(.subtitle){
    font-size: 12px;
}
.infoDescription :global(.slideBtnHolder) a:hover,
.infoDescription :global(.slideBtnHolder) a:focus,
.noomBtn:hover,
.noomBtn:focus{
    background-color: #FF8E01;
    transform: scale(1.1);
}
.infoImgBox, .infoVidBox{
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
}

.headerVideo video{
    max-width: 100%;
    border-radius: 10px;
}

:global(.mobile) .headerImage, :global(.mobile) .headerVideo{
    margin: 25px auto;
    max-height: 150px;
    width: 90%;
}
.infoImgBox img{
    max-height: 150px;
}
:global(.mobile) .infoImgBox img,
:global(.mobile) .infoVidBox video{
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-height: auto;
}
.loading{
    /* margin: 50px auto;
    position: absolute;
    z-index: 10;
    margin-top: 50%; */
}
.section :global(.container){
    transition-duration: .3s;
    position: relative;
}
.loadingHolder{
    height: 100%;
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 5;
    padding-bottom: 150px;
}
.loadingHolder span{
    font-size: 20px;
    background-color: rgba(255,255,255,.4);
    padding: 5px 10px;
    border-radius: 10px;
    font-weight: 500;
    margin-top: -50px;
}
:global(.mobile) .loadingHolder span{
    font-size: 16px;
    margin-top: -10px;
}
.loadingHolder img{
    max-width: 80%;
}
.blur{
    filter: blur(5px);
    -webkit-filter: blur(5px);
}
.centerText{
    display: block;
    margin: 0 auto;
    text-align: left;
    font-size: 12px;
    /* margin-top: 5px; */
    line-height: 1.2;
}
hr {
    margin: 5px 0 20px;
}
.bold{
    font-weight: 500;
}
.subtitle{
    font-size: 12px;
    font-style: italic;
}
.dotLine{
    border-top: dashed 1px #000;
    background-color: transparent;
    margin: 20px 0;
    opacity: .4;
}
.invoiceRow{
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    margin: 25px 0 5px;
}
.invoice h3{
    font-size: 20px;
    font-weight: 500;
    color: #000;
}
.invoice ul{
    font-size: 16px;
}
.invoice li{
    list-style: none !important;
    display: flex;
    /* align-items: center; */
}
.invoice li:before{
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    background-color: #FF8E01;
    position: absolute;
    border-radius: 100px;
    margin-left: -17px;
    margin-top: 6px;
}
.invoice p{
    color: #000;
}
b, strong {
    font-weight: bold !important;
  }