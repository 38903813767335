.container {
  width: 817px;
  border-radius: 16px;
  margin: 130px auto 40px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--neutral-colors-white);
  padding: 50px 80px;
}

.container :global(.cf-assessment-result-container){
  margin: 50px 0 0;
  padding: 0;
  border-radius: 16px;
  box-shadow: 0 0;
}

:global(.mobile) .container {
  width: 100%;
  margin-top: 0px;
  border-radius: 0;
  padding-left: 20px;
  padding-right: 20px;
}

.header {
  position: relative;
  width: 757px;
  transform: translate(-50px, 0);
  margin-top: -20px;
  border: solid 1px var(--neutral-colors-gray-10);
  padding: 20px;
  border-radius: 17px;
  box-sizing: border-box;
  background-color: #f2f2f2;
}

:global(.mobile) .header {
  width: 100%;
  transform: translate(0, 0);
}

.headerText {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 17px;
  padding: 10px;
}

.headerBody {
  display: flex;
  align-items: center;
}

.headerDate {
  margin-left: auto;
}

.headerTitle {
  margin-left: 20px;
}

.title {
  font-weight: bold;
}

.circle {
  width: 80px;
  height: 80px;
  margin: 0 0 0 9px;
  background-image: linear-gradient(to bottom, rgba(252, 176, 77, 0.5), rgba(240, 72, 1, 0));
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}

.circle img {
  width: 100%;
}
