.cf-fluencyActivity {
}

.cf-fluencyActivity video {
  width: 100%;
}
.content {
  width: 650px;
}
:global(.mobile) .content {
  width: 100%;
}

.titleBar {
  display: flex;
  align-items: center;
  margin: 32px 0 16px;
}

:global(.mobile) .titleBar {
  margin-top: 10px;
}

.titleBar :global(.lite .control-button) {
  width: 26px;
  height: 26px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  color: var(--neutral-colors-black);
  margin-right: 6px;
}

.video {
  display: block;
  border-radius: 16px;
  width: 100%;
  transition-duration: 0.3s;
  margin: 35px auto;
}

.paragraphFocus .video {
  width: 50%;
  /* max-height: 200px; */
}

:global(.mobile) .video video {
  width: 100%;
}

.description {
  display: block;
  width: 100%;
  margin: 0px auto -53px;
  text-align: center;
  font-size: 26px;
  font-weight: 600;
  line-height: 1.4;
  color: var(--chatter-fox);
  /* border: 1px solid rgba(0, 0, 0, 0.25); */
  border-radius: 25px;
  padding: 25px 25px 75px;
}

.btnHolder {
  margin-top: 8px;
  display: flex;
  justify-content: center;
}

:global(.mobile) .btnHolder {
  margin-top: 30px;
  margin-bottom: 30px;
}

.questionBox {
  width: 650px;
  min-height: 115px;
  margin: 26px 0 87px;
  padding: 16px 27px 65px 25px;
  border-radius: 16px;
  background-color: #f1f1f5;
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}

.question {
  display: block;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  text-align: center;
  color: var(--neutral-colors-black);
}

.questionBox :global(.cf-AudioRecorder) {
  position: absolute;
  bottom: -40%;
}
@media only screen and (min-width: 992px) {
}
.practiceBox {
  margin: 10px auto 50px;
}
.soundBtn {
  width: 76px;
  height: 106px;
  background-color: #fff;
  margin: 0 auto;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  text-align: center;
  transition-duration: 0.3s;
  cursor: pointer;
}

.soundBtn img {
  /* height: calc(75px + (114 - 75) * ((100vw - 800px) / (1920 - 800))); */
  /* height: 50px !important; */
  margin: auto !important;
}
.soundBtn.recordBtn {
  background: rgb(248, 164, 69);
  background: linear-gradient(191deg, rgba(248, 164, 69, 1) 0%, rgba(238, 76, 4, 1) 100%);
}

.recordBtnHolder {
  height: 110px;
  display: flex;
  align-items: center;
}

.audioPlayer :global(.cf-audioPlayer-control-button) {
  background-image: linear-gradient(to bottom, #42cf59 -26%, #34a77e 115%) !important;
  border-color: #289467;
}
.soundBtn.recordBtn img {
  height: 50px !important;
}
.soundBtn.recordBtn.recording {
  height: 76px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.soundBtn.recordBtn.recording img {
  animation-name: blink;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.soundBtn.recordBtn.loading {
  height: 76px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.soundBtn.recordBtn.loading img {
  animation-name: blink;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.dotLoading {
  display: flex;
  align-items: center;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

/* Modal */
.modal {
  z-index: 999;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
}

.modalCover {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.modalContainer {
  position: relative;
  z-index: 1;
  min-width: 480px;
  min-height: 300px;
  padding: 20px 50px 10px;
  border-radius: 32px;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.25);
  background-image: linear-gradient(132deg, var(--neutral-colors-white), #f8f9fa 98%);
  margin-top: -30vh;
}

.modalContainer .btnHolder {
  margin-top: 60px;
}

:global(.mobile) .modalContainer {
  width: 100%;
  border-radius: 32px 32px 0 0;
  position: fixed;
  bottom: 0;
  height: auto;
  padding: 20px 0;
  min-width: auto;
}

:global(.mobile) .modalContainer > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modalContentBtn {
  width: 106px;
  height: 100px;
  /* padding: 30px; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 24px;
  border: 0;
  /* box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(144deg, var(--neutral-colors-white), #f8f9fa 101%); */
}

.modalContentBtn div {
  width: 100%;
}

.audioPlayer.modalContentBtn {
}

.audioPlayer.modalContentBtn img {
  width: 40px !important;
  height: auto;
  object-fit: contain;
  margin: 0;
}

.audioPlayer.modalContentBtn > div {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 130px;
}

.audioPlayer.modalContentBtn div {
  display: flex !important;
  position: relative !important;
  justify-content: center !important;
}

.audioPlayer.modalContentBtn div img {
  margin: 6px 0 0 !important;
  height: 30px !important;
}

.audioPlayer.modalContentBtn span {
  display: block;
  width: 50%;
  text-align: center;
  font-size: 12px;
  margin: auto;
}

.modalFooter {
  width: 100%;
  display: flex;
  justify-content: space-around;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 30px 0;
  padding: 0 20px;
}

:global(.mobile) .modalFooter {
  flex-wrap: wrap;
  position: relative;
  margin-top: 30px;
}

:global(.mobile) .modalFooter button {
  margin: 7px;
}
/* ./Modal */

.practiceDescription {
  font-size: 13px;
  display: block;
  text-align: center;
}

.resultBox {
  display: flex;
}
:global(.mobile) .resultBox {
  flex-direction: column;
  padding: 0 30px;
}
.resultBox :global(.cf-audioPlayer) {
  padding: 0 30px 0 0;
}
.resultBox :global(.cf-audioPlayer-controls) {
  margin: 0;
}

.rightCol {
  padding-top: 15px;
}

.totalScore {
  width: fit-content;
  text-align: center;
  font-size: 14px;
  border-radius: 10px 0 50px 50px;
  padding: 20px 12px 0;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -8px;
  left: 50px;
  background-color: var(--chatter-fox);
}
:global(.mobile) .totalScore {
  left: 20px;
}
.totalScore:after {
  content: '';
  display: block;
  background-color: #b14d0a;
  width: 6px;
  height: 8px;
  position: absolute;
  top: 0;
  right: -6px;
  border-radius: 0 10px 0 0;
}
.totalScore .scoreBox {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--chatter-fox);
  color: #fff;
  font-weight: 600;
  /* width: 50px; */
  /* height: 50px; */
  border-radius: 50px;
  font-size: 20px;
  margin: -10px 0 20px 0;
}
.totalScore .scoreText {
  background-color: var(--chatter-fox);
  border-radius: 10px;
  margin-top: -10px;
  padding: 5px 10px;
  color: #fff;
}
.wordsNotice {
  text-align: center;
  width: 100%;
  font-style: italic;
  opacity: 0.7;
  margin: 10px 0 45px 0;
  padding-left: 98px;
}
:global(.mobile) .wordsNotice {
  padding-left: 120px;
  padding-right: 30px;
  margin: 0 auto 30px;
}
.paragraphResult {
  max-width: 500px;
}
:global(.mobile) .paragraphResult {
  max-width: 500px;
}
.paragraphResult span {
  /* margin-right: 5px; */
  font-size: 18px;
  /* color: #207700; */
}
.paragraphResult .word {
  position: relative;
  line-height: 1.7;
}
.wordHolder:after {
  content: '';
  display: block;
  position: absolute;
  width: 95%;
  border-top: 2px dotted;
  left: 0;
  bottom: -6px;
  border-bottom: 0px dotted;
  height: 6px;
  border-color: rgba(0, 0, 0, 0.5);
}
.wordBox {
  position: relative;
  cursor: pointer;
}
:global(.mobile) .wordBox {
  position: static;
}

.wordBox .wordDetails {
  display: block;
  position: absolute;
  border: 1px solid #e4e4e4;
  background-color: rgb(250, 250, 250);
  padding: 15px;
  padding-right: 0;
  border-radius: 10px;
  top: 30px;
  margin-left: 50%;
  left: 0;
  min-width: 250px;
  z-index: 10;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  transform: translateX(-50%);
}
:global(.mobile) .wordBox .wordDetails {
  top: auto;
  bottom: 10px;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.scrollPanel {
  height: 100%;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 15px;
}
.wordBox .wordDetails::before {
  content: '';
  display: block;
  background-color: rgb(250, 250, 250);
  border-top: 1px solid #e4e4e4;
  border-left: 1px solid #e4e4e4;
  width: 15px;
  height: 15px;
  transform: rotate(45deg);
  position: absolute;
  top: -8px;
  left: calc(50% - 8px);
}
:global(.mobile) .wordBox .wordDetails::before {
  display: none;
}
.detailsClose {
  display: none;
}
:global(.mobile) .detailsClose {
  display: block;
  margin-bottom: 10px;
  float: right;
  text-align: right;
  padding-right: 15px;
}
.detailsCover {
  display: block;
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -1;
}
:global(.mobile) .detailsCover {
  display: block;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.wordBox:hover .wordDetails {
  /* display: block; */
}
.detailsRow {
  display: flex;
  width: 100%;
}
.detailsRow span {
  flex: 0 0 50%;
}

.resultTable .tableHeader td {
  background-color: #e4e4e4;
  font-weight: 500;
}
.resultTable td {
  color: #000;
  font-size: 16px;
  border: 1px solid #c4c4c4;
  padding: 5px 20px;
  background-color: #fff;
  text-align: center;
}
.notGood {
  color: #ff0000 !important;
}
.redWord .word {
  color: #ff0000;
  cursor: pointer;
  border-bottom: 2px dotted #4e4e4e;
}
.wordBox .redStyle {
  color: #ff0000;
}
.wordBox .wordHolder.redStyle:after {
  border-bottom-width: 2px;
}
.wordBox .greenStyle {
  color: #207700;
}


.mobilRecordBtnHint {
  display: none;
}
:global(.mobile) .mobilRecordBtnHint {
  /* display: block; */
  margin: 10px auto 0;
  text-align: center;
  border-color: var(--chatter-fox);
  border-style: solid;
  border-width: 0 2px;
  width: fit-content;
  padding: 0px 7px;
  text-transform: uppercase;
  font-size: 80%;
  font-weight: 600;
}