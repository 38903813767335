.container {
  padding: 0 20px;
  max-width: 690px;
  margin: 0 auto;
  padding-top: 20px;
}

.header {
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--chatter-fox);
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  color: var(--neutral-colors-white);
  padding: 20px;
  margin-bottom: 32px;
}
.headerMobile.header {
  position: relative;
  overflow: unset;
  padding: 20px 15px;
}
.headerTitleBox {
  display: flex;
  align-items: center;
  width: 90%;
}
.headerMobile .headerTitleBox {
  width: 100%;
}

.titleHolder {
}

/* .headerMobile {
    display: flex;
    flex-direction: column;
    gap: 1vh;
} */

/* .headerMobile .headerTitleBox {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: var(--chatter-fox);
    padding: 15px 10px;
    margin-top: 15px;
    border-radius: 15px;
} */

/* .headerMobile .headerTitleBox{
    flex-direction: row-reverse;
} */

.header h2 {
  font-size: 18px !important;
  font-weight: 400;
  color: var(--neutral-colors-white);
  /* font-size: calc(20px + (30 - 20) * ((100vw - 700px) / (1920 - 700))) !important; */
}

/* .headerMobile h2 {
    font-size: 18px !important;
    font-weight: 400;
    order: 2;
    width: 100%;
    color: var(--neutral-colors-white);
} */
.header h3 {
  color: var(--neutral-colors-white);
  font-size: 13px !important;
  font-weight: 400;
}
/* .headerMobile h3{
    color: var(--neutral-colors-white);
    font-size: 13px !important;
    font-weight: 400;
} */

.contentModeSwitch {
  margin-top: 25px;
  width: 480px;
}
.contentModeSwitch :global(.react-switch-bg) {
  height: 40px !important;
  border-radius: 50px !important;
  width: 480px !important;
}
.contentModeSwitch :global(.react-switch-handle) {
  height: 38px !important;
  color: #000;
  width: 50% !important;
  border-radius: 50px !important;
}
.contentModeSwitch :global(.react-switch-handle) div {
  width: 100%;
  height: 100%;
}

.header .moduleImage {
  width: 64px;
  min-width: 64px;
  height: 64px;
  background-color: #fff5e1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin-right: 20px;
}

.header .moduleImage img {
  max-width: 40px;
  max-height: 40px;
}

/* .headerMobile .moduleImage {
    order: 1;
    overflow: hidden;
    border-radius: 5px;
} */

/* .headerMobile .moduleImage img {
    width: 100%;
} */

.body {
  padding: 0 0 10vh 0;
}
:global(.mobile) .body {
  padding-bottom: 80px;
}
.switchHolder {
  width: 100%;
  max-width: 485px;
  margin-top: 20px;
}
.headerMobile .switchHolder {
  position: absolute;
  top: -60px;
  margin: 0;
}
@media only screen and (min-width: 800px) {
  .practiceAgain {
    display: flex;
    flex-direction: row;
    gap: 2vw;
    max-width: 700px;
    margin: 0 auto;
  }

  .practiceAgain .btn {
    font-size: 17px;
  }
}
