.container{
    max-width: 700px;
    margin: 0 auto;
    padding: 0 25px;
}
.container section{
    padding-top: 40px;
}
.planContainer{
    color: #fff;
    border-radius: 8px;
    overflow: hidden;
}
.planContainer table h5{
    color: #fff;
    margin: 15px 0 5px;
    text-transform: capitalize;
}
.planContainer{
    background-image: linear-gradient(90deg, #8a9197 0%, #bbc5ca 100%);
}
.planContainer.active{
    background-image: linear-gradient(90deg, #1671d2 0%, #31a9eb 100%);
}
.planContainer table{
    width: 100%;
}
.planContainer td{
    padding: 5px 20px;
    vertical-align: middle;
}
.planContainer tr:last-child td{
    padding: 5px 20px 15px;
}
.planContainer td:first-child{
    background-color: rgba(0,0,0,.3);
    width: 30%;
    white-space: nowrap;
}
.linksHolder {
    display: flex;
    flex-wrap: wrap;
}
.linksHolder a {
    margin: 10px 15px 0 0;
    border-radius: 50px;
    background-color: rgba(0,0,0,.05);
    text-decoration: none;
    padding: 5px 10px;
    display: block;
    font-weight: 500;
}
.noPlan{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    padding: 50px;
}
.noPlan .text{
    font-size: 18px;
    font-weight: 300;
    margin: 25px 0 75px;
}

.unsubscribeAlert :global(.react-confirm-alert-body h1){
    position: relative;
    top: unset;
    left: unset;
    margin-top: -30px;
    margin-left: -50px;
    color: #c70000;
}
.unsubscribeAlert.withCredit :global(.react-confirm-alert){
    height: 300px;
}
:global(.mobile).unsubscribeAlert.withCredit :global(.react-confirm-alert){
    height: 345px;
}
.canceledDate{
    text-decoration: line-through;
}