.audioRecorder{

}
.soundBtn {
    width: 76px;
    height: 106px;
    background-color: #fff;
    margin: 0 auto;
    box-shadow: 0 0 5px rgba(0,0,0,.3);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
  }
  
  .soundBtn img {
    /* height: calc(75px + (114 - 75) * ((100vw - 800px) / (1920 - 800))); */
    height: 50px !important;
    margin: auto !important;
  }