.container {
  padding: 50px 0;
}

.description {
  width: 650px;
  margin: 0 0 24px;
  font-size: 16px;
  font-weight: 300;
  color: var(--neutral-colors-black);
}

.description.short{
  font-size: 22px;
  font-weight: 400;
}

:global(.mobile) .description {
  width: auto;
}

.content {
  width: 650px;
}

:global(.mobile) .content {
  width: auto;
}

.image {
  /* width: 650px; */
  height: 168px;
  margin-top: 24px;
  border-radius: 16px;
  overflow: hidden;
}

:global(.mobile) .image {
  height: 200px;
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.recordButton {
  position: relative;
  /* width: 76px; */
  height: 106px;
  margin: -50px auto 0;
  /* box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.25), inset 0 1px 1px 0 rgba(0, 0, 0, 0.1); */
  /* background-image: linear-gradient(149deg, var(--neutral-colors-white), #f8f9fa 103%); */
  border-radius: 50px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.recordButton .btnHolder {
  position: relative;
  width: 76px;
  height: 106px;
  /* margin: -50px auto 0; */
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.25), inset 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(149deg, var(--neutral-colors-white), #f8f9fa 103%);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.recordButton img {
  /* height: calc(75px + (114 - 75) * ((100vw - 800px) / (1920 - 800))); */
  /* height: 30px !important;
  cursor: pointer;
  margin: auto !important; */
}

.text {
  margin-top: 16px;
  font-size: 13px;
  font-weight: 300;
  color: var(--neutral-colors-black);
  text-align: center;
}

:global(.mobile) .text {
  margin-top: 40px;
  margin-bottom: 20px;
}

.buttonGroup {
  display: flex;
  gap: 10px;
  margin-top: 24px;
}

:global(.mobile) .buttonGroup {
  flex-wrap: wrap;
  justify-content: center;
}
:global(.mobile) .buttonGroup button {
  min-width: 150px;
  max-width: 100% !important;
  width: auto;
}

.modal {
  z-index: 999;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalCover {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.modalContainer {
  position: relative;
  z-index: 1;
  width: 480px;
  height: 250px;
  border-radius: 32px;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.25);
  background-image: linear-gradient(132deg, var(--neutral-colors-white), #f8f9fa 98%);
}

:global(.mobile) .modalContainer {
  width: 100%;
  border-radius: 32px 32px 0 0;
  bottom: 0px;
  position: fixed;
  z-index: 9999;
  height: auto;
}

.modalFooter {
  width: 100%;
  display: flex;
  justify-content: space-around;
  position: absolute;
  bottom: 0;
  margin: 30px 0;
  padding: 0 20px;
}

:global(.mobile) .modalFooter {
  flex-wrap: wrap;
  margin: 10px 0;
  position: relative;
}

:global(.mobile) .modalFooter button {
  margin-top: 10px;
  margin-bottom: 10px;
}

.msgHolder {
  text-align: center;
  width: 419px;
  /* height: 147px; */
  padding: 15px;
  border-radius: 24px;
  margin: 40px auto 0;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #eef1f6);
  display: flex;
  align-items: center;
  justify-content: center;
}

:global(.mobile) .msgHolder {
  flex-direction: column;
  width: 80%;
  margin-top: 25px;
}

.modalContentIcon {
  margin-right: 15px;
}

:global(.mobile) .modalContentIcon {
  margin-right: 0;
  margin-bottom: 30px;
}

.msgHolder p {
  color: #000;
  text-align: left;
  margin: 0;
}

.contentBtnHolder {
  width: 240px;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: -60px auto 0;
}

.modalContentBtn {
  width: 106px;
  height: 120px;
  /* padding: 30px; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 24px;
  border: 0;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(144deg, var(--neutral-colors-white), #f8f9fa 101%);
}
