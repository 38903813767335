@font-face {
  font-family: Metropolis;
  src: url(Metropolis-Thin.otf);
  font-weight: 100;
}
@font-face {
  font-family: Metropolis;
  src: url(Metropolis-ExtraLight.otf);
  font-weight: 200;
}
@font-face {
  font-family: Metropolis;
  src: url(Metropolis-Light.otf);
  font-weight: 300;
}
@font-face {
  font-family: Metropolis;
  src: url(Metropolis-Regular.otf);
  font-weight: 400;
}
@font-face {
  font-family: Metropolis;
  src: url(Metropolis-Medium.otf);
  font-weight: 500;
}
@font-face {
  font-family: Metropolis;
  src: url(Metropolis-SemiBold.otf);
  font-weight: 600;
}
@font-face {
  font-family: Metropolis;
  src: url(Metropolis-Bold.otf);
  font-weight: 700;
}
@font-face {
  font-family: Metropolis;
  src: url(Metropolis-ExtraBold.otf);
  font-weight: 800;
}
@font-face {
  font-family: Metropolis;
  src: url(Metropolis-Black.otf);
  font-weight: 900;
}