.app {
  display: flex;
}

.appContent {
  width: calc(100% - 85px);
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
}

.content {
  margin-top: 110px;
}

:global(.mobile) .content {
  overflow: auto;
  margin-top: 67px;
  /* padding-bottom: 80px; */
  height: calc(100vh - 67px);
}

.loading {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

@media only screen and (max-width: 800px) {
  .appContent {
    /* padding-top: 5rem; */
  }
}
