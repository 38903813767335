.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 190px);
}

.fullBackground {
  position: fixed;
  top: 0px;
  left: 0;
  background-color: var(--chatter-fox);
  width: 100vw;
  height: 100vh;
  z-index: 0;
  background: url('../../../assets/images/assessment/waveBgWhite.svg') var(--chatter-fox) repeat-x;
  background-position: center -250px;
  background-size: 15%;
}
.whiteBg .fullBackground {
  background: url('../../../assets/images/assessment/waveBgOrange.svg') var(--chatter-fox) repeat-x;
  background-color: transparent;
  background-position: center -250px;
  background-size: 15%;
}
:global(.mobile) .fullBackground, 
:global(.mobile) .whiteBg .fullBackground {
  background-position: center -250px;
  background-size: 55%;
}
.fullBackground.cover {
  z-index: 3;
  height: 32px;
}
:global(.mobile) .fullBackground.cover {
  z-index: -1;
  height: 0;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.title {
  margin: 0 0 33px;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  color: var(--neutral-colors-white);
}

.defaultIcon {
  height: 70px !important;
  width: 70px !important;
}
.icon {
  align-self: center;
  margin: 0 15px 0 10px;
  position: relative;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon span {
  font-size: 55px;
  font-weight: 600;
  text-align: center;
  color: var(--neutral-colors-black);
  z-index: 1;
  padding: 5px 0 0;
}

.icon img {
  width: 45px;
  height: 45px;
  position: relative;
  z-index: 1;
}

.scoreBox{
  color: #fff;
  position: absolute;
  z-index: 1;
  background-color: var(--chatter-fox);
  border-radius: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  font-weight: 500;
  font-size: 22px;
  padding: 3px 0 0;
  bottom: 0;
  right: 0;
  border: 2px solid #fff;
}
.scoreBox > span{
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: -5px;
}
.scoreTitle{
  display: block;
  font-size: 12px !important;
  margin-top: -5px;
  margin-bottom: -10px !important;
}

.scoreProgress {
  transform: rotate(30deg);
  position: absolute;
  left: 0;
  top: 0;
}

.scoreProgress .line {
  stroke-dasharray: 408;
}

.scoreProgress .background {
  fill: var(--neutral-colors-gray-10);
}

.coachScore {
  width: 58px;
  height: 58px;
  border-radius: 50px;
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 26px;
  padding-top: 2px;
  color: var(--neutral-colors-black);
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--neutral-colors-white);
}

.message {
  margin-top: 24px;
  font-size: 16px;
  font-weight: 300;
  color: var(--neutral-colors-white);
  text-align: center;
}
.whiteBg .message {
  color: var(--neutral-colors-black);
}

:global(.mobile) .message {
  max-width: 80%;
  max-width: 300px;
}

.nextLesson {
  text-align: center;
  margin-top: 72px;
  font-size: 16px;
  font-weight: 300;
  color: var(--neutral-colors-white);
}
.whiteBg .nextLesson {
  color: var(--neutral-colors-black);
}

.nextLesson span {
  display: block;
  font-weight: 600;
}

.btnHolder {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.btnHolder button {
  margin: 10px 0;
}
