.container {
  position: relative;
  min-height: 500px !important;
  padding: 35px 25px !important;
  transition: min-height .5s ease-in;
}

:global(.mobile) .container {
  padding: 35px 25px 35px !important;
}

.optionHit.container{
  min-height: 100px !important;
}
:global(.mobile) .optionHit.container{
  min-height: 500px !important;
}
.cardsHolder{
  width: 600px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

:global(.mobile) .cardsHolder{
  width: 100%;
  justify-content: start;
}

.cardsHolder :global(.progress){
  height: .7rem;
}


.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 80%;
  border-radius: 20px;
  color: #fff;
  font-size: 32px;
  font-weight: 600;
  margin: 10px auto 0;
  background: url('../../../assets/images/assessment/waveBgWhite.svg') #fa6400 center;
  background-size: 40%;
  line-height: 1.3;
  padding: 15px 30px 30px;
  transition-duration: .3s;
  /* transform: translateY(0); */
}

:global(.mobile) .body{
  /* transform: translateY(-20px); */
  margin-top: 15px;
}
:global(.mobile) .optionHit .body{
  /* transform: translateY(-20px); */
  margin-top: 0px;
}


.cardsHolder :global(.progress){
  margin-bottom: 25px;
  overflow: unset;
}
.cardsHolder :global(.progress-bar){
  overflow: unset;
  padding: 0; 
  margin: 0;
}
.cardsHolder :global(.progressBar){
  overflow: unset;
  padding: 0; 
  margin: 0;
}

.horizontalMode .answerBtnHolder{
  display: flex;
}
:global(.mobile) .horizontalMode .answerBtnHolder{
  flex-direction: column;
}
.answerBtn{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 55px;
  font-size: 16px;
  font-weight: 300;
  border-radius: 16px;
  margin: 10px 0;
  border: 0;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(104deg, var(--neutral-colors-white), #f8f9fa 91%);
  transition-duration: .3s;
}

:global(.mobile) .answerBtn{
  height: 65px;
}

.horizontalMode .answerBtn{
  min-width: 200px;
  width: auto;
  padding: 0 20px;
  margin: 10px;
}

.answerBtn:disabled {
  filter: none;
  color: rgb(43, 43, 43) !important;
}

.correctAnswer{
  border: 5px solid #3aa445 !important;
}

.wrongAnswer{
  border: 5px solid #e00000 !important;
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-45px);
    opacity: .3;
    height: 0px;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    height: 90px;
  }
}

.answerBox{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-around;
  bottom: -115px;
  left: 0;
  width: 100%;
  /* height: 140px; */
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
  animation: slideInFromTop .3s ease-in;
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(200px);
  }
  100% {
    transform: translateY(0);
  }
}

:global(.mobile) .answerBox {
  flex-direction: column;
  bottom: 0;
  position: fixed;
  z-index: 9990;
  border-radius: 16px 16px 0 0;
  padding: 30px 10px 50px;
  box-shadow: 0 0px 12px 0 rgba(0, 0, 0, 0.25);
  animation: slideInFromBottom .3s ease-out;
}


.answerBox p {
  width: 50%;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin: 0;
}

:global(.mobile) .answerBox p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px !important;
  margin: 0 auto 20px;
  height: 45px;
  width: 280px;
  border-radius: 50px;
}

.answerBox.correct{
  background-image: linear-gradient(100deg, #c8e8b3, #f8f9fa 91%);
}

.answerBox.correct p {
  color: #3aa445;
}

.answerBox.wrong{
  background-image: linear-gradient(100deg, #ffcfcf, #f8f9fa 91%);
}

.answerBox.wrong p {
  color: #e00000;
}

:global(.mobile) .answerBox{
  background: none #fff !important;
}

:global(.mobile) .answerBox.correct p {
  background-color: #c8e8b3;
}

:global(.mobile) .answerBox.wrong p{
  background-color: #ffcfcf;
}

.audioPlayer :global(.cf-audioPlayer-control-button){
  width: 75px !important;
  height: 75px !important;
}
.audioPlayer :global(.cf-audioPlayer-control-button) img{
  width: 27px !important;
  /* margin-left: 2px; */
}

.audioSubtitle {
  margin: 0px 0 15px;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  color: var(--neutral-colors-white);
}