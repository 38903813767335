.mainForm{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Gilroy', sans-serif;
}

.mainForm :global(h1, h2, h3, h4, h5, h6){
  font-family: 'Gilroy', sans-serif;
}

.header{
  text-align: center;
  font-family: 'Gilroy', sans-serif;
  color: #004c6d;
  font-weight: 100;
  font-size: 25px;
  margin-bottom: 30px;
}

.loginForm{
  max-width: 360px;
  margin: 0 auto;
}

.loginForm input {
  display: block;
  width: 100%;
  height: calc(1.4em + 0.94rem + 3.7px);
  padding: 0.47rem 0.8rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  color: #000;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid #808080;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 20px;
  outline: 0;
}

.loginForm label{
    font-size: 15px;
    color: #4C4C4C;
    text-transform: capitalize;
}

.loginForm input:hover{
  border-color: #000;
}
.loginForm input:focus, .loginForm input:active{
  border-color: #000;
  box-shadow: 0px 1px 5px #CBCBCB;
}

.mainBtn {
  background-color: #f15a24;
  border: 1px solid #ed1c24;
  color: #fff;
  line-height: 50px;
  font-size: 18px;
  font-weight: normal;
  font-family: PT Sans;
  transition: all .3s;
  margin-right: 40px;
  margin-top: 15px;
}

.mainBtn:hover, .mainBtn:focus {
  background-color: #ed1c24;
  border: 1px solid #f15a24;
}