:root {
  --neutral-colors-black: #000;
  --neutral-colors-gray-10: #dcdce9;
  --neutral-colors-gray-20: #c1c7cf;
  --neutral-colors-gray-30: rgba(0, 0, 0, 0.3);
  --neutral-colors-gray-40: rgba(0, 0, 0, 0.4);
  --neutral-colors-gray-50: #777b80;
  --neutral-colors-gray-75: #484a4d;
  --neutral-colors-white: #fff;
  --chatter-fox: #fa6400;
  --chatter-green: #3aa483;
  --ruby: #e02020;
  --citrine: #f7b500;
  --emerald: #6dd400;
  --apatite: #32c5ff;
  --sapphire: #0091ff;
  --iolite: #6236ff;
}

.lessonContainer {
  max-width: 817px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 190px);
  padding-top: 20px;
}

.lessonContainer section {
  width: 100%;
  padding: 0;
}

.lessonContainer .container {
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--neutral-colors-white);
  border-radius: 16px;
  min-height: 500px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-bottom: 50px;
}

.mobile .lessonContainer {
  background-color: #fff;
  height: 100%;
  padding-bottom: 80px !important;
}

.mobile .lessonContainer .container {
  border-radius: 0;
  box-shadow: 0 0;
  flex: 0 0 auto;
  margin-bottom: 0;
}

.lessonContainer .lesson-description {
  margin: 25px 0;
}

.lessonContainer .introduction {
  padding: 0;
}

.video-lessons.video {
  display: flex;
  max-width: 100%;
  height: 360px;
}

.video-lessons video {
  max-width: 100%;
  max-height: 100%;
}

.lesson-msg-holder {
  text-align: center;
  margin-bottom: 50px;
}

.progressBar {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  padding-top: 25px;
  margin-bottom: 30px;
}

.progressBar .progress-bar {
  background-color: var(--chatter-fox) !important;
  box-shadow: none !important;
}

.progressBar svg {
  display: none;
  color: #afafaf;
  margin-right: 6px;
}

.progressBar > div {
  width: 100%;
}

.feedbacklab {
  bottom: 150px;
  top: unset !important;
}

.feedbacklab span {
  border-radius: 30px 30px 0 0;
}

.wave-bg-white {
  background: url('./app/views/assets/images/assessment/waveBgWhite.svg') #fa6400 center;
  background-size: 40%;
}

.wave-bg-gray {
  background: url('./app/views/assets/images/assessment/waveBg.svg') center;
  background-size: 18%;
  background-repeat: repeat-x;
  background-position: -100px bottom;
}

/* Confirm Modal and Swal Styles */
#react-confirm-alert {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}
.react-confirm-alert-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.react-confirm-alert,
.swal2-modal {
  margin-top: -15vh;
  position: relative;
  z-index: 1;
  width: 480px;
  min-height: 250px;
  border-radius: 32px;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.25);
  background-image: linear-gradient(132deg, var(--neutral-colors-white), #f8f9fa 98%);
}

.mobile .react-confirm-alert,
.mobile .swal2-modal {
  width: 100%;
  border-radius: 32px 32px 0 0;
  position: fixed;
  left: 0;
  bottom: 0;
}

.react-confirm-alert-body,
.swal2-content {
  text-align: left !important;
  width: 419px;
  padding: 50px 15px 15px 90px !important;
  border-radius: 24px;
  margin: 0 auto 0 !important;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 70px, #eef1f6 100%);
  display: flex;
  align-items: baseline;
  justify-content: start !important;
  font-size: 15px;
  font-weight: 400;
  box-shadow: 0 0;
  font-family: Metropolis;
  flex-direction: column;
  min-height: 155px;
  line-height: 1.3;
}

.mobile .react-confirm-alert-body,
.mobile .swal2-content {
  width: auto;
  padding-left: 100px;
}

.react-confirm-alert-body::before,
.swal2-content::before {
  content: '';
  display: block;
  width: 50px;
  height: 50px;
  background: url(./app/views/assets/images/attentionIcon.png) no-repeat center;
  background-size: contain;
  position: absolute;
  left: 50px;
}

.mobile .react-confirm-alert-body::before,
.mobile .swal2-content::before {
  left: 30px;
}

.react-confirm-alert-body h1,
.swal2-header h2 {
  font-size: 18px !important;
  display: block;
  border-bottom: 0;
  color: #000;
  text-align: left;
  position: absolute;
  top: 25px;
  left: 40px;
}
.swal2-header .swal2-icon {
  display: none !important;
}

.react-confirm-alert-button-group {
  position: absolute;
  bottom: 25px;
  left: 0;
  display: flex;
  width: 100%;
  padding: 0 25px;
}

.react-confirm-alert-button-group button,
.swal2-actions button {
  display: flex;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  width: 253px;
  height: 51px;
  margin: 0 10px 3px;
  border-radius: 27px !important;
  border-style: solid;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  text-transform: none;
  align-items: center;
  justify-content: center;
}

.react-confirm-alert-button-group button:last-child,
.swal2-actions .swal2-confirm {
  background-image: linear-gradient(to bottom, #42cf59 -26%, #34a77e 115%) !important;
  border-color: #289467;
  border-width: 0 0 3px 0;
  color: var(--neutral-colors-white);
}

.react-confirm-alert-button-group button:last-child:hover,
.react-confirm-alert-button-group button:last-child:focus,
.react-confirm-alert-button-group button:last-child:active,
.swal2-actions .swal2-confirm:hover,
.swal2-actions .swal2-confirm:focus,
.swal2-actions .swal2-confirm:active {
  background-image: linear-gradient(to bottom, #34a77e -26%, #289467 115%) !important;
  border-color: #289467;
  border-width: 3px 0 0 0;
  color: var(--neutral-colors-white);
}

.react-confirm-alert-button-group button:first-child {
  background-color: var(--neutral-colors-white);
  background-image: linear-gradient(to bottom, var(--neutral-colors-white) -26%, #f8f9fa 115%);
  border-color: transparent;
  border-width: 0 0 3px 0;
  color: #000;
}

.react-confirm-alert-button-group button:first-child:hover,
.react-confirm-alert-button-group button:first-child:focus,
.react-confirm-alert-button-group button:first-child:active {
  background-image: linear-gradient(to bottom, rgb(240, 240, 240) -26%, rgb(248, 248, 248) 115%);
  border-color: #b1b1b1;
  border-width: 3px 1px 0 1px;
  color: #000;
}

.react-confirm-alert-overlay.cf-one-button button {
  margin: 0 auto;
}
/* ./Confirm Modal and Swal Styles */

@media only screen and (max-width: 500px) {
  .feedbacklab {
    right: 30px !important;
  }

  .feedbacklab span {
    padding: 5px 15px i !important;
  }
}
