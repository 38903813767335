.container {
}

.header {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 650px;
  height: 40px;
  margin: 15px auto;
  padding: 8px;
  border-radius: 24px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--neutral-colors-white);
}

.headerBullet {
  width: 24px;
  height: 24px;
  margin: 0 12px 0 0;
  padding: 0 9px 0 8px;
  border-radius: 50px;
  background-color: var(--chatter-fox);
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: var(--neutral-colors-white);
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerText {
  font-weight: 400;
  font-size: 16px;
  color: var(--neutral-colors-black);
  white-space: nowrap;
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.lesson {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 150px;
  cursor: pointer;
  align-items: center;
  margin: 10px 0 20px;
  padding: 0 10px;
}

.figure {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 104px;
  height: 104px;
  border-radius: 100px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--neutral-colors-white);
}

.orangeLine {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  /* width: 92px;
  height: 92px; */
  width: 100%;
  height: 100%;
  /* border: solid 4px var(--chatter-fox); */
}

[data-target='#unlockWarn'] .orangeLine {
  /* border: solid 4px var(--neutral-colors-gray-20); */
}

.imgBack {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  /* width: 76px; */
  /* height: 76px; */
  width: 100%;
  height: 100%;
  background-color: #fff5e1;
  overflow: hidden;
}

[data-target='#unlockWarn'] .imgBack {
  filter: grayscale(100%);
  opacity: 0.8;
}

.figure img {
  width: 100%;
  height: 100%;
}

.title {
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  color: var(--neutral-colors-black);
  margin-top: 8px;
}

.feedbackBox {
  width: 330px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

:global(.mobile) .feedbackBox :global(.medium) {
  width: auto;
  padding: 0 25px;
}

.modal {
  z-index: 9999;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
}

.modalCover {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.modalContainer {
  position: relative;
  z-index: 1;
  width: 480px;
  height: 250px;
  border-radius: 32px;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.25);
  background-image: linear-gradient(132deg, var(--neutral-colors-white), #f8f9fa 98%);
}

:global(.mobile) .modalContainer {
  width: 100%;
  border-radius: 32px 32px 0 0;
  bottom: 0px;
  position: fixed;
  z-index: 9999;
  height: auto;
}

.modalFooter {
  width: 100%;
  display: flex;
  justify-content: space-around;
  position: absolute;
  bottom: 0;
  margin: 30px 0;
  padding: 0 20px;
}

:global(.mobile) .modalFooter {
  flex-wrap: wrap;
  margin: 10px 0;
  position: relative;
}

:global(.mobile) .modalFooter button {
  margin-top: 10px;
  margin-bottom: 10px;
}

.msgHolder {
  text-align: center;
  width: 419px;
  /* height: 147px; */
  padding: 15px;
  border-radius: 24px;
  margin: 40px auto 0;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #eef1f6);
  display: flex;
  align-items: center;
  justify-content: center;
}

:global(.mobile) .msgHolder {
  flex-direction: column;
  width: 80%;
  margin-top: 25px;
}

.modalContentIcon {
  margin-right: 15px;
}
.modalContentIcon img {
  width: 60px;
}

:global(.mobile) .modalContentIcon {
  margin-right: 0;
  margin-bottom: 30px;
}

.msgHolder p {
  color: #000;
  text-align: left;
  margin: 0;
}

.contentBtnHolder {
  width: 240px;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: -60px auto 0;
}

.modalContentBtn {
  width: 106px;
  height: 120px;
  /* padding: 30px; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 24px;
  border: 0;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(144deg, var(--neutral-colors-white), #f8f9fa 101%);
}
