@import '../../assets/fonts/metropolis/metropolis.css';

:root {
  --neutral-colors-black: #000;
  --neutral-colors-gray-20: #c1c7cf;
  --neutral-colors-white: #fff;
  --chatter-fox: #fa6400;
  --chatter-green: #3aa483;
  --ruby: #e02020;
  --neutral-colors-gray-50: #777b80;
  --citrine: #f7b500;
  --emerald: #6dd400;
  --neutral-colors-gray-10: #dcdce9;
  --apatite: #32c5ff;
  --neutral-colors-gray-75: #484a4d;
  --sapphire: #0091ff;
  --iolite: #6236ff;
}
:global(h1),
:global(h2),
:global(h3),
:global(h4),
:global(h5),
:global(p),
:global(span) {
  font-family: 'Metropolis';
}
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.highlight {
  color: var(--chatter-fox);
}
.title {
  font-family: Metropolis;
  font-size: 27px;
  font-weight: 600;
  line-height: 1.2;
  margin: 30px auto;
  letter-spacing: normal;
  text-align: center;
  color: var(--neutral-colors-black);
}
button.btnPrimary {
  width: 253px;
  height: 51px;
  margin: 0 0 3px;
  /* border-radius: 27px;
  background-image: linear-gradient(to bottom, #42cf59 -26%, #34a77e 115%);
  border-color: #289467; */
  /* border-style: solid; */
  /* border-width: 0 0 3px 0; */
  /* font-size: 14px;
  font-weight: 300;
  text-align: center;
  color: var(--neutral-colors-white);
  text-transform: none; */
  margin: 30px auto;
}
button.btnPrimary:hover,
button.btnPrimary:focus,
button.btnPrimary:active {
  /* background-image: linear-gradient(to bottom, #34a77e -26%, #289467 115%);
  border-color: #289467;
  border-width: 3px 0 0 0;
  color: var(--neutral-colors-white); */
}
button.btnSecondary {
  width: 253px;
  height: 51px;
  margin: 0 0 3px;
  border-radius: 27px;
  background-image: linear-gradient(to bottom, rgb(248, 248, 248) -26%, rgb(240, 240, 240) 115%);
  border-color: #b1b1b1;
  border-style: solid;
  border-width: 0px 0px 3px 0px;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  color: #000;
  margin: 30px auto;
  text-transform: none;
}
button.btnSecondary:hover,
button.btnSecondary:focus,
button.btnSecondary:active {
  background-image: linear-gradient(to bottom, rgb(240, 240, 240) -26%, rgb(248, 248, 248) 115%);
  border-color: #b1b1b1;
  border-width: 3px 1px 0 1px;
  color: #000;
}
.contentTop {
  width: 100%;
  margin-bottom: auto;
}
.subTitle {
  text-align: center;
}

.note {
  display: flex;
  align-items: center;
  border: 1px solid #43b5d7;
  padding: 2px 10px 2px 3px;
  border-radius: 50px;
  width: 80%;
  margin: 40px auto 60px;
  background-color: #f3fdfe;
}
.note h3 {
  color: #025772 !important;
  font-size: 16px !important;
  font-weight: 100;
  opacity: 0.8;
  text-align: left;
  margin: 0;
}
.noteIcon {
  background-color: #43b5d7;
  width: 35px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-weight: 400;
  font-size: 26px;
  margin-right: 15px;
  color: #fff;
}
.note.warning {
  border-color: #ffc000;
  background-color: #fcf6e4;
}
.note.warning .noteIcon {
  background-color: #ffc000;
}
.note.warning h3 {
  color: #794b03 !important;
}
.contentContainerHolder {
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: baseline;
  position: relative;
  align-items: center;
}
.contentContainer h2 {
  text-align: center;
  font-size: 30px;
}
.contentContainerHolder h3 {
  font-weight: 100;
  opacity: 0.8;
  text-align: left;
  margin: 0;
}

.contentContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 817px;
  min-height: 470px;
  border-radius: 16px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(129deg, var(--neutral-colors-white), #f8f9fa 96%);
  background-color: var(--neutral-colors-white);
  margin: 0 auto;
  transition: height .5s ease-in-out;
}
:global(.mobile) .contentContainer {
  align-items: baseline;
}
.edgeSpace {
  padding: 40px 60px !important;
}
.waveBg {
  background-image: url('../../assets/images/assessment/waveBg.svg');
  background-position-y: 220px;
  background-repeat: repeat-x;
}

.footer {
  display: flex;
  justify-content: space-between;
  color: #8f9399;
  font-size: 12px;
  width: 100%;
  height: 64px;
  margin: 30px 0 0;
  padding: 24px;
  border-radius: 0;
}
.mobileBr {
  display: none;
}
@media only screen and (max-width: 820px) {
  .desktopBr {
    display: none;
  }
  .mobileBr {
    display: block;
  }
  .contentContainer {
    /* padding: 30px 10vw; */
    box-shadow: none;
    /* margin-bottom: auto; */
    min-height: 100vh;
    overflow: auto;
  }
  .waveBg {
    background-position-y: 50vh;
  }
  .title,
  .subTitle {
    text-align: left;
  }
  .edgeSpace {
    padding: 30px 10vw !important;
  }
  .contentContainer h2 {
    text-align: center;
    font-size: 22px !important;
  }
  .note {
    display: flex;
    position: relative;
    align-items: center;
    border: 2px solid #43b5d7;
    padding: 12px;
    border-radius: 0 20px 20px 20px;
    width: 100%;
    margin: 30px auto 20px;
    background-color: #f3fdfe;
    border-right-width: 0;
    border-bottom-width: 0px;
    border-top-width: 3px;
    background-color: transparent !important;
    max-width: 450px;
  }
  .note h3 {
    font-size: 15px !important;
  }
  .noteIcon {
    background-color: #43b5d7;
    width: 35px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    font-weight: 400;
    font-size: 26px;
    margin-right: 15px;
    color: #fff;
    position: absolute;
    top: -18px;
    left: -18px;
  }
}
@media only screen and (max-height: 660px) {
  .edgeSpace {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
}
