.form {
}

.note {
}

.termsAndConditions {
  /* display: flex; */
  /* font-family: Gilroy; */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: #2c2c2c;
}

.termsAndConditions a {
  color: var(--chatter-fox);
  text-decoration: none;
  font-weight: 400;
}

.termsAndConditions :global(.form-check-input) {
  border-radius: 250px;
  width: 16px;
  height: 16px;
  border-color: var(--chatter-fox);
  flex: 0 0 16px;
  position: relative;
  margin: 2px 5px 0 0;
}

.termsAndConditions :global(.invalid-feedback) {
  margin-top: 0px !important;
}

.login {
  text-align: center;
  font-size: 14px;
  color: var(--neutral-colors-black);
}

.login a {
  margin-left: 4px;
  font-size: 14px;
  color: var(--chatter-fox);
  text-decoration: none;
}

.note.listView {
  text-align: justify;
  margin-top: 30px;
}

.note.listView span {
  display: flex;
  align-items: center;
  margin: 8px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.note.listView span:before {
  content: '';
  display: block;
  width: 18px;
  height: 18px;
  margin-right: 12px;
  flex: 0 0 18px;
  background: url(../../assets/images/tick.svg) no-repeat;
}

.form button {
  margin: 32px auto 10px;
}
