.container {
  max-width: 817px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.header {
  width: 817px;
  height: 164px;
  margin: 24px 0 0 0;
  padding: 0 83px;
  border-radius: 16px;
  background-image: linear-gradient(to bottom, #fcb04d -9%, #f04801 117%);
  display: flex;
  justify-content: space-between;
}

:global(.mobile) .header {
  position: fix;
  width: 100vw;
  height: 260px;
  margin: 0;
  padding: 0 0 42px;
  border-radius: 0 0 1000px 1000px;
  background-image: linear-gradient(to bottom, #fcb04d, #f04801);
  flex-direction: column;
  align-items: center;
  justify-content: unset;
}

.header .coach {
  width: 164px;
  height: 164px;
  padding: 20px 19px 18px 10px;
  background-image: linear-gradient(to bottom, rgba(252, 176, 77, 0.5), rgba(240, 72, 1, 0));
  border-radius: 50%;
}

.header .coach .score {
  position: absolute;
  width: 43px;
  height: 43px;
  margin: 43px 90px 40px 2px;
  padding: 4px 12px 7px 13px;
  background-image: linear-gradient(to bottom, #fcb04d, rgba(240, 72, 1, 0));
  border-radius: 50%;
  z-index: 1;
}

.header .coach .score span {
  font-size: 28.4px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--neutral-colors-white);
}

.header .coach .circle {
  width: 126px;
  height: 126px;
  margin: 0 0 0 9px;
  padding: 15px 16px 17px;
  background-image: linear-gradient(to bottom, rgba(252, 176, 77, 0.5), rgba(240, 72, 1, 0));
  border-radius: 50%;
}

.header .coach .circle .image {
  width: 94px;
  height: 94px;
  border-radius: 50%;
  overflow: hidden;
}

.header .coach img {
  position: relative;
  left: -10px;
  width: 110px;
}

.title {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: var(--neutral-colors-white);
  align-self: center;
}

:global(.mobile) .title {
  text-align: center;
}

.title span {
  display: block;
  font-size: 16px;
  font-weight: 500;
}

.content {
  padding-top: 50px;
}

.lessons {
  max-width: 651px;
  margin: 0 auto;
}

:global(.mobile) .lessons {
  max-width: 312px;
}

.tips {
  margin-top: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tips .coach {
  width: 66px;
  height: 66px;
  border-radius: 50%;
  overflow: hidden;
}

.tips .coach img {
  position: relative;
  left: -7px;
  width: 75px;
}

.tips .text {
  max-width: 568px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: var(--neutral-colors-black);
  display: flex;
  flex-direction: column;
  width: calc(100% - 80px);
}

.tips .text span {
  font-size: 18px;
  font-weight: 500;
}

.wait {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
}

:global(.mobile) .wait {
  margin-top: 43px;
}

.wait img {
  width: 32px;
}

.wait span {
  margin: 8px 0 0 0;
  font-size: 15px;
  font-weight: normal;
  color: var(--neutral-colors-black);
}

.wait a {
  font-size: 15px;
  font-weight: 600;
  color: var(--sapphire);
}
