.container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  min-height: 100%;
  max-height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  max-width: 1110px;
  min-width: 302px;
  width: calc(627px + (866 - 627) * ((100vw - 800px) / (1920 - 800)));
  position: relative;
  padding: 0 15px;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 25px;
}

.title {
  font-family: 'Lato';
  font-size: 3vw;
  font-weight: bold;
  color: #f86969;
  padding-top: 30px;
}

.card {
  flex: 1 0 auto;
}

.card .img {
  width: calc(147px + (216 - 147) * ((100vw - 800px) / (1920 - 800)));
  height: calc(147px + (216 - 147) * ((100vw - 800px) / (1920 - 800)));
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: -92px;
  position: relative;
  z-index: 1;
  border: 15px solid #fff;
  border-width: calc(5px + (15 - 5) * ((100vw - 800px) / (1920 - 800)));
}

.card .img img {
  vertical-align: middle;
  border-style: none;
  left: calc(-165px + (-218 + 165) * ((100vw - 800px) / (1920 - 800)));
  position: relative;
  height: calc(220px + (293 - 220) * ((100vw - 800px) / (1920 - 800)));
}

.card .content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 auto;
  height: 75%;
  background-color: rgb(250, 211, 211);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.27);
  border-radius: 12px;
  padding: 110px 9px 10px 9px;

  font-family: 'Lato';
  font-weight: bold;
  color: #000000;
}

.card .content .text1 {
  display: inline-block;
  width: 100%;
  font-size: 25px;
}

.card .content .text2 {
  display: inline-block;
  width: 100%;
  font-size: 20px;
}

.btnGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}

.btnGroup button {
  margin: 7px 0;
}

@media only screen and (max-width: 760px) {
  .container {
    width: 100%;
  }

  .title {
    font-size: 22px;
  }

  .card {
    margin-top: 37px;
  }

  .card .img {
    width: 147px;
    height: 147px;
    border: 5px solid #fff;
  }

  .card .img img {
    left: -165px;
    height: 220px;
  }

  .card .content {
    border-radius: 23px;
    box-shadow: unset;
  }

  .card .content .text1 {
    font-size: 14px;
    font-weight: normal;
  }

  .card .content .text2 {
    font-size: 14px;
    font-weight: normal;
  }

  .btnGroup {
    display: flex;
    flex-direction: column;
  }

  .btnPrimary {
    order: 1;
  }

  .btnSecondary {
    order: 2;
    margin-top: 10px;
    margin-right: auto;
  }
}
