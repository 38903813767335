.menu {
  width: 85px;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 4;
  padding: 42px 6px 24px 7px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--neutral-colors-white);

  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.logo {
  text-align: center;
}

.logo img {
  width: 50px;
  object-fit: contain;
  cursor: pointer;
}

.mainMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 80%;
  justify-content: center;
}
.mainMenu :global(.cf-nav-item), 
.mainMenu > :global(.cf-nav-link){
  flex: 0 0 auto !important;
}

:global(.cf-nav-item),
.feedback {
  width: 72px;
  height: 72px;
  margin: 0 0 10px;
  padding: 17px 0px 9px;
  cursor: pointer;
}
:global(.mobile .cf-nav-item),
.feedback {
  padding: 9px 0px 9px;
}
:global(.cf-nav-item) img,
.feedback img{
  width: 33px;
  height: 33px;
  /* object-fit: cover; */
  /* object-position: 0 -10px; */
  margin-bottom: 5px; 
}
:global(.cf-nav-item) a,
.feedback {
  text-decoration: none !important;
}
:global(.cf-nav-item) span,
.feedback span {
  font-size: 12px !important;
  color: #000;
  font-weight: 300;
  display: block;
  text-align: center;
}
:global(.cf-nav-item .active) span,
.feedback :global(.active) span {
  color: #3484d8;
}

.feedback {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

:global(.cf-nav) {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  background-color: #fff;
  border-top: 1px solid #ddd;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.16);
}

:global(.mobile .cf-nav) {
  height: 72px;
  padding: 0 12px 0 11px;
  border-radius: 32px 32px 0 0;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.25);
  background-color: var(--neutral-colors-white);
}

:global(.cf-nav-tabs) {
  border-bottom: 1px solid #dee2e6;
}

:global(.cf-nav-fill) :global(.cf-nav-item),
:global(.cf-nav-fill) > :global(.cf-nav-link) {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

:global(.cf-nav-tabs) :global(.cf-nav-item.show) :global(.cf-nav-link),
:global(.cf-nav-tabs) :global(.cf-nav-link.active) {
  color: #495057;
  /* background-color: #fff; */
  border-color: #dee2e6 #dee2e6 #fff;
}

:global(.cf-nav-link) {
  display: block;
  fill: #707070;
  stroke: #707070;
  background: unset;
}

:global(.cf-nav-link.active) {
  display: block;
  fill: #039ff4;
  stroke: #039ff4;
}
