.container{
    max-width: 817px;
    margin: 0 auto;
    display: flex;
    padding-top: 24px;
}

:global(.mobile) .container{
    flex-direction: column;
    padding-bottom: 80px;
}
.profileDetailsBack{
    background-color: rgba(255,255,255,1);
    position: absolute;
    top: 0;
    z-index: 100;
    height: 66px;
    width: 120px;
    display: flex;
    align-items: center;
    padding-left: 20px;
}
.profileDetailsBack .back{
    font-size: 22px;
    margin-right: 20px;
    cursor: pointer;
}
.menuCol{
    width: 320px;
    margin-right: auto;
}
:global(.mobile) .menuCol{
    width: auto;
    margin: auto;
}
.contentCol{
    width: 100%;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 30px;
}
:global(.mobile) .contentCol{
    width: 100%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0;
}
.mCard{
    display: flex;
    align-items: center;
    position: relative;
    width: 320px;
    min-height: 65px;
    padding: 18px 24px 17px 24px;
    border-radius: 15px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--neutral-colors-white);
    border: solid 2px rgba(255,255,255,0);
    margin-bottom: 20px;
}
.mCard.linkable:hover{
    border: solid 2px var(--chatter-green);
}
.mCard.linkable{
    cursor: pointer;
    padding-right: 40px;
}
.mCard.linkable::after{
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border: 1px solid var(--neutral-colors-gray-20);
    border-width: 2px 2px 0 0;
    transform: rotate(45deg);
    position: absolute;
    right: 24px;
    transition-duration: .3s !important;
    transition: all;
}
.mCard.linkable:hover::after{
    right: 15px;
    border-color: var(--chatter-green);
}
.mCard.active{
    border: solid 2px var(--chatter-green);
}
.mCard .contentHolder{
    display: flex;
    flex-direction: column;
    margin-left: 16px;
}
.mCard .title{
    font-size: 15px;
    font-weight: 600;
    line-height: 1.6;
    letter-spacing: normal;
    color: var(--neutral-colors-black);
}
.mCard .content{
    font-size: 14px;
    font-weight: 300;
    line-height: 1.14;
    letter-spacing: normal;
    color: var(--neutral-colors-black);
    text-decoration: none; 
}
.mCard .icon{
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mCard .icon.small{
    width: 30px;
    height: 30px;
    margin: 0 15px;
}
.mCard .icon img{
    width: 100%;
    /* border-radius: 100px; */
}
.circleImage img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 100px;
}
.mCard .badge{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: #f1f1f5; 
    font-size: 18px;
    font-weight: 600;
}
.profilePic{
    width: 80px;
    height: 80px;
    position: relative;
}
.profilePic img{
    width: 100%;
    height: 100%;
}
.profilePic::before {
    position: absolute;
    left: 0;
    top: 78%;
    content: '';
    font-size: 20px;
    background: url(../../assets/images/camera.png) no-repeat; 
    background-size: 100%;
    width: 40px;
    height: 40px;
    right: 0;
    margin: 0 auto;
}
.profilePic input {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 35px;
    overflow: hidden;
    top: 87%;
    opacity: 0;
}
.userInfo{
    margin-top: 20px;
}
.userInfo .username{
    display: block;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.6;
    text-align: center;
    color: var(--neutral-colors-black);
    text-align: center;
}
.userInfo .plan{
    display: block;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.33;
    color: var(--neutral-colors-gray-75);
    text-align: center;
}
.btnHolder {
    width: 100%;
    display: flex;
    margin-top: 35px;
    display: flex;
    justify-content: center;
  }
  .btnHolder button{
    margin: 0 10px;
  }
  .btnHolder :global(.rightIconHolder){
      display: none;
  }
  .btnHolder :global(.labelHolder){
      width: 100%;
      text-align: left;
      padding-top: 3px;
  }
  .profileContainer .profileRow{
    padding: 15px 0;
    border-bottom: solid 1px var(--neutral-colors-gray-10);
  }
  .profileContainer .lastRow{
      border-bottom: 0 solid;
      margin-bottom: 30px;
  }
  .profileContainer{
      margin: 15px auto;
      min-width: 85%;
  }
  .centerBtn button{
    margin: 0 auto;
  }
  .formCheck{
    display: inline-block;
    margin-right: 1rem;
    padding: 0;
  }
  
    .edit .radioRow {
        display: flex;
    }
    .edit :global(.form-check-inline){
        width: 30%;
    }
  .edit.profileContainer input, 
  .edit.profileContainer select, 
  .edit.profileContainer select:focus, 
  .edit.profileContainer select:read-only,
  .edit.profileContainer select:read-only:focus,
  .edit .profileRadio, 
  .edit .genderText{
    padding-left: 25px;
    padding-right: 25px;
    background-color: #fff;
    border-radius: 50px;
    height: 65px;
    width: 100%;
    margin-right: -10px;
    margin-left: 0;
    border: 0 solid;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1) !important;
  }
  .edit .profileRadio {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px !important;
}
  .edit .profileRadio input{
    width: 20px;
    height: 20px;
    margin: 0 7px 0 0;
    border: solid 2.4px var(--neutral-colors-gray-20) !important;
    background-color: #f1f1f5;
   }
  .edit .profileRadio input:checked{
      width: 20px;
      height: 20px;
    margin: 0 7px 0 0;
    border: solid 2.4px var(--neutral-colors-gray-20) !important;
    background-color: #f1f1f5;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' style='background-color: #fa6400'/%3e%3c/svg%3e");
}
.edit .profileRadio input:checked::before{
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    margin: 2px 0 0 2px;
    border-radius: 50px;
    background-color: var(--chatter-fox);
}
  .edit .profileLabel, .edit .normalBr{
      display: none;
    }
  .edit .profileRow{
      border-bottom:  0 solid;
  }
  .profileContainer input:read-only, .profileContainer select:read-only, .profileRadio, .genderText{
    font-size: 15px;
    font-weight: 300 !important;
    color: var(--neutral-colors-black) !important;
    text-transform: capitalize;
    font-family: 'Metropolis';
  }
  .profileContainer input:read-only, .profileContainer select:read-only,
  .profileContainer input:read-only:focus, .profileContainer select:read-only:focus {
    padding: 0;
    background-color: transparent;
    border: 0 solid;
    box-shadow: 0 0 0 0 rgba(13,110,253,.25);
    height: 20px;
}
.profileContainer select:read-only{
    margin-left: -3px;
}
.profileLabel {
    font-size: 15px;
    font-weight: 700;
    color: var(--neutral-colors-black);
  }

.profileContainer :global(.form-control):focus{
    box-shadow: 0 0 0;
}
.changePassContainer{
    max-width: 320px;
    margin: 0 auto;
    padding: 60px 10px;
}