.container {
    width: 484px;
    margin: 0 auto;
    padding-top: 48px !important;
}
:global(.mobile) .container {
    width: 100%;
    max-width: 484px;
    padding-left: 20px;
    padding-right: 20px;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.input {
    flex: 0 0 318px;
}
:global(.mobile) .input {
    flex: 0 0 80%;
}

.input input {
    width: 318px;
    height: 51px;
    padding: 20px 20px ;
    border-radius: 28px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--neutral-colors-white);
}
:global(.mobile) .input input {
    width: 100%;
}

.input :global(.dropdown-menu){
    /* padding: 8px 0 24px; */
    margin-top: 5px;
    border-radius: 28px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--neutral-colors-white);
}
:global(.mobile) .input :global(.dropdown-menu){
    width: 100%;
}

.btn {
    flex: 0 0 150px;
    height: 7vh;
    max-height: 50px;
    margin: 0;
    text-transform: none;
}
:global(.mobile) .btn {
    flex: 0 0 18%;
    display: flex;
    justify-content: center;
}
:global(.mobile) .btn:after {
    content: "Search";
}
:global(.mobile) .btn :global(.labelHolder){
    display: none;
}
:global(.mobile) .btn :global(.labelHolder)::before{
}

.body {
    padding-top: 24px;
}

.player {
    width: auto;
    margin: 0 auto;
    height: 300px;
    border-radius: 16px;
    overflow: hidden;
}
:global(.mobile) .player {
    width: 100% !important;
    max-width: 444px;
    height: calc((100vw - 40px) / 1.6) !important;
    max-height: 278px;
}

.subtitle {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    text-align: center;
    margin-top: 24px;
}

.highlight {
    color: #FB5014;
}

.controls {
    margin-top: 3vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.control {
    display: flex;
    border-radius: 50px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
    font-size: 19px;
    margin: 0 10px;
    cursor: pointer;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 52px;
    height: 52px;
    align-items: center;
    justify-content: center;
}

.step {
    color: #484a4d    ;
    background-color: #fff;
    font-size: 28px;
}

.play {
    color: #484a4d;
    background-color: #fff;
}

.step:hover {
    color: #fff;
    background-color: #484a4d;
}

.play:hover {
    color: #fff;
    background-color: #484a4d;
}

.reset {
    color: #484a4d;
    background-color: #fff;
}

.reset:hover {
    color: #fff;
    background-color: #484a4d;
}

.textAnalysis {
    margin-top: 5vh;
}

.textAnalysis h3,
.definitions h3 {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--neutral-colors-black);
}

.textAnalysis .phoneme,
.definitions span{
    display: block;
    font-size: 16px;
    font-weight: 300;
    color: var(--neutral-colors-black);
    margin-bottom: 24px;
}

.definitions {
    margin-top: 5vh;
    padding-bottom: 10vh;
}

.definitions h3{

}

@media only screen and (max-width: 480px) {
    .container h3 {
        font-size: 17px !important;
    }

    .reset {
        right: calc((100vw / 2) - 160px);
    }
}