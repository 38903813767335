.container {
  padding-bottom: 100px;
  max-width: 650px;
  width: 100%;
  margin: 0 auto;
}

:global(.mobile) .container {
  background-color: #f1f1f5;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
}

.headerBox {
  width: 100%;
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--chatter-fox);
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  color: var(--neutral-colors-white);
  padding: 20px;
  margin-bottom: 32px;
}
.headerMobile.headerBox {
  position: relative;
  overflow: unset;
  padding: 20px 15px;
}
.headerBox .headerTitleBox {
  display: flex;
  align-items: center;
  width: 90%;
}
.headerMobile.headerBox .headerTitleBox {
  width: 100%;
}

.headerBox .titleHolder {
}

.headerBox h2 {
  font-size: 18px !important;
  font-weight: 400;
  color: var(--neutral-colors-white);
}

.headerBox h3 {
  color: var(--neutral-colors-white);
  font-size: 13px !important;
  font-weight: 400;
}

.headerBox .moduleImage {
  width: 64px;
  min-width: 64px;
  height: 64px;
  background-color: #fff5e1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin-right: 20px;
}

.headerBox .moduleImage img {
  max-width: 40px;
  max-height: 40px;
}

.note {
  width: 100%;
  margin: 24px 0;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.71;
  color: var(--neutral-colors-black);
}

.section {
  margin-bottom: 24px;
}

.section.overview {
  margin-top: 30px;
}

.header {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 650px;
  height: 40px;
  margin: 15px auto;
  padding: 8px;
  border-radius: 24px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--neutral-colors-white);
}

.headerBullet {
  width: 24px;
  height: 24px;
  margin: 0 12px 0 0;
  padding: 0 9px 0 8px;
  border-radius: 50px;
  background-color: var(--chatter-fox);
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: var(--neutral-colors-white);
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerText {
  font-weight: 400;
  font-size: 16px;
  color: var(--neutral-colors-black);
  white-space: nowrap;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.record {
}

.box {
  width: 100%;
  height: 84px;
  border-radius: 50px 10px 10px 50px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 0 24px 0 3px;
  border-radius: 16px;
  border: solid 1px var(--neutral-colors-gray-10);
  background-color: #f1f1f5;
  z-index: 1;
}

.feedbackIcon {
  height: 100%;
  position: absolute;
  right: 20px;
  top: 0;
  display: none;
  align-items: center;
}

.feedbackIcon img {
  width: 40px;
}

._hover {
  cursor: pointer;
}

.avatar{
  width: 55px;
  height: 55px;
  border-radius: 50px;
  object-fit: cover;
  margin-right: 30px;
  position: relative;
  z-index: 5;
}
.chatMode .avatar{
  margin-right: 15px;
}

.feedbackIcon:global(._show) img,
:global(._show) .feedbackIcon img {
  display: none;
}

.feedbackIcon:global(._show):after,
:global(._show) .feedbackIcon:after {
  font-family: 'FontAwesome';
  content: '\f106';
  font-size: 30px;
  color: var(--neutral-colors-white);
  background-color: var(--neutral-colors-gray-10);
  border-radius: 60px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  padding: 0 0 5px 0;
}

.feedbacks {
}

.feedback {
  width: 100%;
  margin-top: 10px;
  padding: 10px 23px;
  border-radius: 16px;
  border: solid 1px var(--neutral-colors-gray-10);
  background-color: var(--neutral-colors-gray-10);
  display: flex;
  align-items: center;
  position: relative;
}
.feedback.chatMode {
  background-color: transparent;
  border: 0 solid;
  align-items: flex-start;
}
.feedback:before {
  content: "";
  display: block;
  background-color: var(--neutral-colors-gray-10);
  width: 15px;
  height: 15px;
  position: absolute;
  transform: rotate(45deg);
  top: -7px;
  left: 41px;
}
.feedback.feedback.chatMode:before {
  display: none;
}

.feedback .audioPlayer {
  padding: unset;
  width: 100%;
}
.feedback.chatMode .audioPlayer {
  margin-top: 1px;
  padding: 10px 23px;
  border-radius: 0 16px 16px;
  border: solid 1px var(--neutral-colors-gray-10);
  background-color: var(--neutral-colors-gray-10);
}
:global(.mobile) .feedback.chatMode .audioPlayer {
  position: relative;
  left: -67px;
  width: calc(100% + 85px);
  top: 20px;
  margin-bottom: 20px;
  border-radius: 16px;
}

.audioHolder{
  width: 100%;
}
.feedback .coachingDetails{
  display: none;
  width: 100%;
}
:global(.mobile) .feedback .coachingDetails {
  flex-direction: column;
  display: flex !important;
}

.coachName{
  font-size: 14px;
  margin-left: 0px;
}
.coachingDate{
  font-size: 14px;
  font-style: italic;
  opacity: .5;
  margin-left: 10px;
}
:global(.mobile) .coachingDate{
  margin-left: 0;
}

.feedback.chatMode .coachingDetails{
  display: block;
}

:global(.mobile) .audioPlayer {
  padding: 0;
}

.feedback .audioPlayer > div {
  margin: unset;
}

.feedback .audioPlayer :global(.control-button) {
  height: 38px !important;
  width: 38px !important;
  margin: 0;
  flex: 0 0 auto;
}

.feedback .audioPlayer :global(.control-button) img {
  width: 12px !important;
}

.scoreList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

:global(.mobile) .scoreList {
  flex-direction: column;
}

.score {
  width: 48%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  font-size: 13px;
  line-height: 1.85;
  color: var(--neutral-colors-black);
  padding: 0 10px;
}

:global(.mobile) .score {
  width: 100%;
}

.rating {
}

.rating span img {
  width: 18px;
}

.practiceAgain {
  width: 100%;
  min-height: 147px;
  margin: 32px 0;
  padding: 16px 24px 16px 16px;
  border-radius: 16px;
  background-color: var(--neutral-colors-gray-10);
}

.practiceAgain .textBox {
  width: 100%;
  margin: 0 0 16px;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.85;
  text-align: center;
  color: var(--neutral-colors-black);
}

.practiceAgain .btn {
  margin: 0 auto;
}
