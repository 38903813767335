/* NotificationIcon */
.cf-notification-icon a{
  text-decoration: none;
  white-space: nowrap;
}
.cf-notification-icon .dropdown-menu {
  width: 400px;
  padding: 18px 10px 0;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--neutral-colors-white);
  margin-top: 40px;
  border-radius: 16px;
  inset: 0px 0px auto auto;
  transform: translate(15px, 24px);
  transition: all .3s;
}

.cf-notification-icon .dropdown-menu::before {
  border-top: 1px solid #dfe3e7;
  border-left: 1px solid #dfe3e7;
  content: '';
  position: absolute;
  top: -1px;
  right: 1.2rem;
  width: 0.75rem;
  height: 0.75rem;
  display: block;
  background: #ffffff;
  transform: rotate(45deg) translate(-7px);
  z-index: 10;
  box-sizing: border-box;
}

.cf-notification-icon .dropdown-menu .dropdown-header {
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  color: var(--neutral-colors-black);
  text-align: center;
  margin-bottom: 10px;
}

.cf-notification-icon .dropdown-menu .dropdown-item {
  height: 104px;
  margin: 8px 0;
  padding-left: 0;
  border-radius: 16px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--neutral-colors-white);
}

.cf-notification-content {
  padding: 13px 10px 28px 10px;
  border-radius: 24px;
  background-image: linear-gradient(to bottom, #eef1f6, rgba(255, 255, 255, 0));
}

.cf-notification-close {
  position: absolute;
  right: 24px;
  top: 22px;
  display: none;
}

.mobile .cf-notification-close {
  display: block;
}

.site-navbar ul li a img:hover {
  filter: unset;
}

.cf-badge {
  position: absolute;
  top: -5px;
  right: -4px;
  padding: 0px 5px;
  border-radius: 50%;
  background-color: red;
  color: white;
  border: 2px solid #fff;
  font-size: 11px;
}
/* NotificationPage */

.cf-notification-page {
  margin: 0 auto;
  padding-right: 50px;
  padding-left: 50px;
}

.cf-notification-item {
  background-color: #fff;
  margin-bottom: 10px;
  cursor: pointer;
}

/* NotificationContent */

.cf-notification {
}

.cf-notification .cf-header {
  max-width: 1110px;
  width: 100%;
  margin: 0 auto;
  height: 210px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 18px 21px;
}

.cf-notification .cf-header .cf-header-text {
  text-align: center;
  font-family: 'Lato';
  font-size: 30px;
  font-weight: bold;
  color: #f86969;
}

.cf-notification .cf-header .cf-body {
  display: flex;
  flex-direction: row;
}

.cf-notification .cf-header .cf-body .cf-icon {
  width: 90px;
  height: 90px;
  margin-right: 19px;
}

.cf-notification .cf-header .cf-body .cf-titles {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 19px 0;
}

.cf-notification .cf-header .cf-body .cf-titles .cf-title {
  font-family: 'Lato';
  font-weight: bold;
  font-size: 18px;
}

.cf-notification .cf-header .cf-body .cf-titles .cf-subtitle {
  font-family: 'Arial';
  font-size: 16px;
  color: #afafaf;
}

.cf-notification .cf-header .cf-body .cf-date {
  font-family: 'Arial';
  font-size: 16px;
  width: 80px;
  text-align: right;
  align-self: end;
  color: #afafaf;
}

.cf-noNotification {
  display: inline-block;
  width: 100%;
  font-family: 'Arial';
  font-size: 16px;
  text-align: center;
  color: #afafaf;
  margin-bottom: 45px;
}

@media only screen and (max-width: 800px) {
  .cf-notification .cf-header {
    height: 110px;
    padding: 15px 21px;
  }

  .cf-notification .cf-header .cf-header-text {
    font-size: 17px;
  }

  .cf-notification .cf-header .cf-body {
  }

  .cf-notification .cf-header .cf-body .cf-icon {
    width: 35px;
    height: 35px;
    margin-right: 8px;
  }

  .cf-notification .cf-header .cf-body .cf-titles {
    padding: unset;
  }

  .cf-notification .cf-header .cf-body .cf-titles .cf-title {
    font-size: 16px;
  }

  .cf-notification .cf-header .cf-body .cf-titles .cf-subtitle {
    font-size: 14px;
  }

  .cf-notification .cf-header .cf-body .cf-date {
    font-size: 14px;
    width: 60px;
  }
}

/* Mobile */

.mobile .cf-notification-icon .dropdown-menu {
  width: 100%;
  margin-top: 0;
  border-radius: 0 0 16px 16px;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.25);
  transform: unset !important;
}

.mobile .cf-notification-icon .dropdown-menu::before {
  border-top: 1px solid #dfe3e7;
  border-left: 1px solid #dfe3e7;
  content: '';
  position: absolute;
  top: -1px;
  right: 1.2rem;
  width: 0.75rem;
  height: 0.75rem;
  display: block;
  background: #ffffff;
  transform: rotate(45deg) translate(-7px);
  z-index: 10;
  box-sizing: border-box;
  display: none;
}
