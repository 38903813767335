.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.figure {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 104px;
  height: 104px;
  border-radius: 100px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--neutral-colors-white);
}

.line {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  /* width: 92px;
  height: 92px;
  border: solid 4px var(--neutral-colors-gray-20); */
}

.imgBack {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  /* width: 76px;
  height: 76px; */
  background-color: #fff5e1;
  overflow: hidden;
}

.figure img {
  width: 100%;
}

.message {
  margin-top: 16px;
  margin-bottom: 53px;
  font-size: 16px;
  font-weight: 300;
  color: var(--neutral-colors-black);
  text-align: center;
}

:global(.mobile) .message {
  max-width: 300px;
  text-align: center;
}

.backgroundPattern {
  width: 100%;
  height: 300px;
  position: fixed;
  top: 0;
  left: 0;
}

.backgroundPattern.header {
  z-index: 2;
  height: 32px;
  background-position: -100px -8px;
}
