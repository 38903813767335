.container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  min-height: 100%;
  max-height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  max-width: 1110px;
  min-width: 302px;
  width: calc(627px + (866 - 627) * ((100vw - 800px) / (1920 - 800)));
  position: relative;
  padding: 0 15px;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 25px;
}

.title {
  font-family: 'Lato';
  font-size: 3vw;
  font-weight: bold;
  color: #f86969;
  padding-top: 30px;
}

.content {
}

.video {
  border-radius: 16px;
  width: 100%;
}

:global(.mobile) .video video {
  width: 100%;
}

.btnGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}

.btnGroup button {
  margin: 7px 0;
}

.languages {
  padding-bottom: 20px;
}

@media only screen and (max-width: 760px) {
  .container {
    width: 100%;
  }

  .title {
    font-size: 22px;
  }

  .btnGroup {
    display: flex;
    flex-direction: column;
  }

  .btnPrimary {
    order: 1;
  }

  .btnSecondary {
    order: 2;
    margin-top: 10px;
    margin-right: auto;
  }
}
