.container {
  height: calc(100vh - 200px);
  border-radius: 11px;
  /* border: 2px solid #e5e5e5;
  background: #fff; */
  padding: 40px 50px;
  max-width: 817px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

:global(.mobile) .container {
  align-items: baseline;
}

.body {
}

.header {
  text-align: center;
}

.header h4 {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
  color: var(--neutral-colors-black);
}

.header p {
  font-size: 13px;
  line-height: 1.38;
  color: var(--neutral-colors-black);
}

.content {
  margin-top: 5vh;
}

.searchHolder {
  display: flex;
  justify-content: space-between;
}
:global(.mobile) .searchHolder {
  flex-direction: column;
}

.input input {
  width: 318px;
  height: 51px;
  padding: 15px 15px 15px 20px;
  border-radius: 28px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--neutral-colors-white);
  font-size: 14px;
  font-weight: 300;
}

:global(.mobile) input input {
  margin-bottom: 16px;
  width: 100%;
}

.btn {
  min-height: 45px;
  max-height: 55px;
  margin-left: 16px;
  font-size: 14px;
  font-weight: 300;
  color: var(--neutral-colors-white);
  max-width: 170px;
}
:global(.mobile) .btn {
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
}

.suggestions {
  margin-top: 5vh;
}

.suggestions h4 {
  font-size: 13px !important;
  color: var(--neutral-colors-black);
  margin-top: 20vh;
  margin-bottom: 20px;
}
:global(.mobile) .suggestions h4 {
  text-align: center;
}

.items {
  margin-top: 5px;
  display: flex;
}
:global(.mobile) .items {
  flex-direction: column;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 48px;
  margin: 0 1px 3px 0;
  border-radius: 27px;
  background-image: linear-gradient(to bottom, var(--neutral-colors-white) -26%, #f8f9fa 115%);
  cursor: pointer;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
  margin: 6px;
}

:global(.mobile) .item {
  width: 100%;
  margin: 0 0 15px 0;
}

.item:first-child {
  margin-left: 0;
}
.item:last-child {
  margin-right: 0;
}

.item:hover {
  background-image: linear-gradient(to top, var(--neutral-colors-white) -26%, #e8eaeb 115%);
}

.item span {
  font-size: 14px;
  font-weight: 300;
  color: var(--neutral-colors-black);
}

.item svg {
  display: none;
}

@media only screen and (max-width: 800px) {
  .header h4 {
    font-size: 16px !important;
  }

  .header p {
    font-size: 1.2vw;
  }
}
