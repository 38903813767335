.toolbar {
  position: fixed;
  z-index: 1001;
  top: 19px;
  left: 30px;
}

.toolbar span {
  font-family: 'Lato';
  font-size: 20px;
  font-weight: bold;
  color: #fb5014;
}

.toolbar img {
  margin-bottom: 7px;
  margin-right: 18px;
  cursor: pointer;
}

.content {
  display: flex;
  flex-direction: column;
  max-width: 1110px;
  width: 100%;
  position: relative;
  margin: 0 auto;
}

.progressBar {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding-top: 25px;
  margin-bottom: 30px;
}

.progressBar svg {
  display: none;
  color: #afafaf;
  margin-right: 6px;
}

.progressBar > div {
  width: 100%;
}

.header {
  display: flex;
  justify-content: center;
  margin-top: 3vh;
}

.header span {
  font-family: 'Lato';
  font-weight: normal;
  font-size: 18px;
  color: #000;
  align-self: center;
}

.header .textBox {
  width: 100%;
  padding: 1vh 50px;
  background-color: #fff;
  border: 3px solid #f86969;
  border-radius: 30px 30px 30px 0;
  text-align: left;
  display: flex;
}

.header img {
  margin-right: 40.5px;
}

.body {
  flex: 1 0 auto;
  display: table;
  text-align: center;
  width: 405px;
  height: 230px;
  border-radius: 20px;
  color: #fff;
  font-size: 32px;
  font-weight: 600;
  margin: 10px auto 0;
  background: url('../../../assets/images/assessment/waveBgWhite.svg') #fa6400 center;
  background-size: 40%;
  line-height: 1.3;
  padding: 30px 30px;
}

.sentence {
  font-size: 21px;
  text-align: left;
  align-items: baseline;
  display: flex;
  padding: 20px;
}

.body span {
  display: table-cell;
  vertical-align: middle;
}

.footer {
  /* margin-bottom: 3vh; */
}

.footerBox {
  width: 100%;
  height: calc(125px + (197 - 125) * ((100vw - 800px) / (1920 - 800)));
  margin: 0 auto;
  padding: 25px;
  border-radius: 30px;
  background-color: #efefef;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
}
.soundBtn {
  width: 76px;
  height: 106px;
  background-color: #fff;
  margin: -50px auto 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.soundBtn img {
  /* height: calc(75px + (114 - 75) * ((100vw - 800px) / (1920 - 800))); */
  height: 50px !important;
  cursor: pointer;
  margin: auto !important;
}

.footerBox span {
  margin-top: 10px;

  font-family: 'Lato';
  font-weight: bold;
  font-size: 18px;
  color: #404040;
}

.footerBox.recorded {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  height: 143.5px;
  padding: 24px 30px;
}

.audioPlayer {
  padding: unset;
}

.audioPlayer img {
  height: 96px;
}

.audioPlayerLite {
  width: 34px;
  height: 34px;
  background-color: #df5b03;
  border-radius: 50px;
  overflow: hidden;
  padding: 0;
  margin-bottom: 10px;
}
.audioPlayerLite img {
  width: 16px;
  height: 16px;
  margin: 0 !important;
}

.footerBox.recorded .waves {
  width: 355px;
}

.soundNote {
  margin: 15px auto;
  font-size: 13px;
  font-weight: 300;
  text-align: center;
  color: var(--neutral-colors-black);
}

.footerBox.recorded span {
  font-size: 20px;
  flex: 1 0 auto;
  text-align: left;
  align-self: center;
}

.contentTop {
  margin-top: -15px;
}

.btnGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max-content;
  margin: 20px auto 0;
}
.btnGroup button {
  margin: 0 10px;
}

.btnPrimary {
  margin: unset;
  text-transform: unset;
}

.btnSecondary {
  margin: unset;
  text-transform: unset;
}
:global(.progress) {
  background-color: #d8d8d8 !important;
}
:global(.progress-bar) {
  background-color: #b2b4b8 !important;
  box-shadow: none !important;
}
:global(.progress-bar::after) {
  content: '';
  display: block;
  width: 37px;
  height: 37px;
  /* background-color: bisque; */
  position: absolute;
  right: -20px;
  background-image: url('../../../assets/images/assessment/foxHead.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
:global(.progress:hover .progress-bar::before) {
  /* content: ""; */
  /* display: block; */
  width: 70px;
  height: 40px;
  background-color: bisque;
  position: absolute;
  right: -20px;
  top: 25px;
}

.description {
  margin: 20px auto 0;
  font-size: 13px;
  font-weight: 300;
}

.btnHolder {
  width: 100%;
  display: flex;
  margin-top: 30px;
  justify-content: space-around;
}

.btnHolder button {
  margin: 0 10px;
}

:global(.mobile) .btnHolder {
  width: 100vw;
  padding: 0 12px;
}

:global(.mobile) .btnHolder button {
  margin: 0 7px;
}

.cardsHolder {
  min-width: 485px;
}

.cardsHolder :global(.progressBar) {
  margin-bottom: 45px;
}

.modal {
  z-index: 999;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
}

.modalCover {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.modalContainer {
  position: relative;
  z-index: 1;
  width: 480px;
  height: 380px;
  border-radius: 32px;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.25);
  background-image: linear-gradient(132deg, var(--neutral-colors-white), #f8f9fa 98%);
}

:global(.mobile) .modalContainer {
  width: 100%;
  border-radius: 32px 32px 0 0;
  position: fixed;
  bottom: 0;
  height: auto;
}

.modalFooter {
  width: 100%;
  display: flex;
  justify-content: space-around;
  position: absolute;
  bottom: 0;
  margin: 30px 0;
  padding: 0 20px;
}

:global(.mobile) .modalFooter {
  flex-wrap: wrap;
  position: relative;
  margin-top: 30px;
}

:global(.mobile) .modalFooter button {
  margin: 5px;
}

.msgHolder {
  text-align: center;
  width: 419px;
  height: 147px;
  padding: 15px;
  border-radius: 24px;
  margin: 40px auto 0;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #eef1f6);
}

:global(.mobile) .msgHolder {
  text-align: center;
  width: auto;
  margin: 40px 25px 0;
  width: auto;
}

.contentBtnHolder {
  width: 240px;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: -60px auto 0;
}

.modalContentBtn {
  width: 106px;
  height: 120px;
  /* padding: 30px; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 24px;
  border: 0;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(144deg, var(--neutral-colors-white), #f8f9fa 101%);
}

.audioPlayer :global(.cf-audioPlayer-control-button) {
  box-shadow: 0 0 !important;
}
.audioPlayer :global(.cf-audioPlayer-label) {
  margin: 0 !important;
}

.audioPlayer.modalContentBtn {
}

.audioPlayer.modalContentBtn img {
  width: 40px !important;
  height: auto;
  object-fit: contain;
  margin: 0;
}

.audioPlayer.modalContentBtn > div {
  flex-direction: column;
}

.audioPlayer.modalContentBtn span {
  display: block;
  width: 50%;
  text-align: center;
  font-size: 12px;
  margin: auto;
}

.audioPlayer :global(.control-button) {
  box-shadow: unset !important;
}

@media only screen and (max-width: 800px) {
  .content {
    /* width: 302px; */
    padding-right: 0.5vw;
    padding-left: 0.5vw;
  }

  /* .progressBar svg {
    display: block;
  } */

  .progressBar > div {
    height: 7px;
    margin-top: 4px;
  }

  .header {
    margin-top: 24px;
  }

  .header span {
    font-size: 14px;
  }

  .header .textBox {
    height: 55px;
    padding: 6px 9px;
    border: 1px solid #f86969;
    border-radius: 10px 10px 10px 0;
  }

  .header img {
    width: 40px;
    margin-right: 10px;
  }

  /* .body span {
    display: inline-block;
    text-align: left;
    margin-top: 25px;
  } */

  /* .footer {
    margin-bottom: 57px;
  } */

  /* .footerBox {
    height: 125px;
    border-radius: 8px;
  } */

  /* .footerBox.record {
    padding: unset;
    margin-bottom: unset;
  } */

  /* .footerBox.record img {
    width: 75px;
    height: 75px;
    margin: 9px auto;
  } */

  /* .footerBox span {
    margin: 8px auto;

    font-weight: normal;
    font-size: 10px;
  } */

  /* .footerBox.recorded {
    height: 99px;
    margin-bottom: 46;
    padding: 22px 10px;
  } */

  /* .audioPlayer img {
    width: 55px !important;
    height: 55px;
  } */

  /* .footerBox.recorded .waves {
    width: 45vw;
  } */

  /* .footerBox.recorded span {
    font-size: 13px;
    padding-left: 5px;
  } */

  .btnGroup {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
  }
  .btnGroup button {
    margin: 5px auto;
  }

  .btnPrimary {
    order: 1;
  }

  .btnSecondary {
    order: 2;
    margin-right: unset;
  }
}

@media only screen and (max-width: 800px) {
  .toolbar {
    display: none;
  }
}

@media only screen and (max-width: 820px) {
  .body {
    width: 80vw;
    height: 350px;
    border-radius: 20px;
  }
  .progressBar {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding-top: 25px;
    margin-bottom: 30px;
  }
}
