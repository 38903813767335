.btn.btnPrimary {
  --primaryLight: #42cf59;
  --primaryDark: #34a77e;
  --primaryBorder: #289467;
  --secondaryLight: rgb(248, 248, 248);
  --secondaryDark: rgb(240, 240, 240);
  --secondaryBorder: #b1b1b1;
}

.btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px auto;
  border-radius: 10px;
  text-transform: uppercase;
  width: calc(298px + (400 - 298) * ((100vw - 800px) / (1920 - 800)));
  height: calc(45px + (77 - 45) * ((100vw - 800px) / (1920 - 800)));
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  font-weight: bold;
  font-size: 1.65vw;
  width: 253px;
  height: 51px;
  margin: 0 0 3px;
  border-radius: 27px;
  border-style: solid;
  border-width: 0 0 3px 0;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  text-transform: none;
}

.label {
  display: block;
  margin: 0 !important;
  white-space: nowrap;
}
.label.noIcon{
  width: 100%;
  text-align: center;
}

.loading{
  padding-top: 5px;
  transform: scale(.8);
}

:global(.medium).btn {
  width: 200px;
}

:global(.mobile .medium).btn {
  width: 165px;
}

:global(.small).btn {
  width: 150px;
}

:global(.mobile.modal) .btn {
  width: auto;
  max-width: 90%;
}

.btn:disabled {
  opacity: 0.7;
  color: inherit !important;
  filter: grayscale(0.3);
}

.btn span {
  margin-bottom: 8px;
  margin-right: 10px;
}

.btnDefault,
.btnDefault:hover:disabled,
.btnDefault:focus:disabled,
.btnDefault:active:disabled {
  background-color: #F5A153;
  border: 0 solid;
  border-radius: 20px;
  color: #fff !important;
}

.btnDefault:hover,
.btnDefault:focus,
.btnDefault:active {
  background-image: linear-gradient(to bottom, #e39d00 -26%, #F5A153 115%);
  /* color: var(--neutral-colors-black); */
}

.btnDefaultOutline,
.btnDefaultOutline:hover:disabled,
.btnDefaultOutline:focus:disabled,
.btnDefaultOutline:active:disabled {
  background-color: #F5EBDB;
  border: 2px solid #F5A153;
  border-radius: 20px;
  color: #b16926 !important;
}

.btnDefaultOutline:hover,
.btnDefaultOutline:focus,
.btnDefaultOutline:active {
  background-image: linear-gradient(to bottom, #fff -26%, #F5EBDB 115%);
  /* color: var(--neutral-colors-black); */
}

.btnPrimary,
.btnPrimary:hover:disabled,
.btnPrimary:focus:disabled,
.btnPrimary:active:disabled {
  background-image: linear-gradient(to bottom, #42cf59 -26%, #34a77e 115%);
  border-color: #289467;
  border-width: 0 0 3px 0;
  color: var(--neutral-colors-white);
}

.btnPrimary:hover,
.btnPrimary:focus,
.btnPrimary:active {
  background-image: linear-gradient(to bottom, #34a77e -26%, #289467 115%);
  border-color: #289467;
  border-width: 3px 0 0 0;
  color: var(--neutral-colors-white);
}

.btnGold,
.btnGold:hover:disabled,
.btnGold:focus:disabled,
.btnGold:active:disabled {
  background-image: linear-gradient(to bottom, #ffc900 -26%, #e39d00 115%);
  border-color: #b17601;
  border-width: 0 0 3px 0;
  color: var(--neutral-colors-black);
}

.btnGold:hover,
.btnGold:focus,
.btnGold:active {
  background-image: linear-gradient(to bottom, #e39d00 -26%, #ffc900 115%);
  border-color: #b17601;
  border-width: 3px 0 0 0;
  color: var(--neutral-colors-black);
  box-shadow: 0 0 0;
}

.btnLink,
.btnLink:hover:disabled,
.btnLink:focus:disabled,
.btnLink:active:disabled {
  background-image: none;
  border-color: transparent;
  border-width: 0 0 3px 0;
  color: var(--neutral-colors-black);
  box-shadow: 0 0;
  background-color: transparent;
  width: auto;
  height: auto;
  padding: 8px 16px 5px;
  text-decoration: underline;
}

.btnLink:hover,
.btnLink:focus,
.btnLink:active {
  background-color: var(--neutral-colors-gray-10);
}

.btnLink .loading :global(.cf-three-dot),
.btnLink .loading :global(.cf-three-dot):after,
.btnLink .loading :global(.cf-three-dot::before){
  background-color: rgba(0,0,0,.4);
  color: rgba(0,0,0,.4);
}

.btnSecondary,
.btnSecondary:hover:disabled,
.btnSecondary:focus:disabled,
.btnSecondary:active:disabled {
  background-color: var(--neutral-colors-white);
  background-image: linear-gradient(to bottom, var(--neutral-colors-white) -26%, #f8f9fa 115%);
  border-color: transparent;
  border-width: 0 0 3px 0;
  color: #000;
}

.btnSecondary:hover,
.btnSecondary:focus,
.btnSecondary:active {
  background-image: linear-gradient(to bottom, rgb(240, 240, 240) -26%, rgb(248, 248, 248) 115%);
  border-color: #b1b1b1;
  border-width: 3px 1px 0 1px;
  color: #000;
}

.btnSecondary .loading :global(.cf-three-dot),
.btnSecondary .loading :global(.cf-three-dot):after,
.btnSecondary .loading :global(.cf-three-dot::before){
  background-color: rgba(0,0,0,.4);
  color: rgba(0,0,0,.4);
}

.btnNone,
.btnNone:hover:disabled,
.btnNone:focus:disabled,
.btnNone:active:disabled {
  justify-content: center;
  background-color: transparent;
  border-color: transparent;
  color: #fff;
  box-shadow: none;
}

.btnNone:hover,
.btnNone:focus,
.btnNone:active {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: transparent;
  /* border-width: 3px 1px 0 1px; */
  color: #fff;
}

.btnNoneBlack,
.btnNoneBlack:hover:disabled,
.btnNoneBlack:focus:disabled,
.btnNoneBlack:active:disabled {
  justify-content: center;
  background-color: transparent;
  border-color: transparent;
  color: #000;
  box-shadow: none;
}

.btnNoneBlack:hover,
.btnNoneBlack:focus,
.btnNoneBlack:active {
  background-color: rgba(255, 255, 255, 0.2);
  border-color: transparent;
  /* border-width: 3px 1px 0 1px; */
  color: #000;
}

.btnNoneBlack .loading :global(.cf-three-dot),
.btnNoneBlack .loading :global(.cf-three-dot):after,
.btnNoneBlack .loading :global(.cf-three-dot::before){
  background-color: rgba(0,0,0,.4);
  color: rgba(0,0,0,.4);
}

.btnWarning,
.btnWarning:hover:disabled,
.btnWarning:focus:disabled,
.btnWarning:active:disabled {
  background-image: linear-gradient(to bottom, #ffc900, #e39d00);
  border-width: 0 0 3px 0;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
  border-color: #b17601;
}

.btnWarning:hover,
.btnWarning:focus,
.btnWarning:active {
  background-image: linear-gradient(to bottom, #9b7900, #a37000);
  border-width: 0 0 3px 0;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
  border-color: #b17601;
}

/* Icons */
.icon {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 5px 0 !important;
  background-size: 100% !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

:global(.left).rightIcon {
  visibility: hidden;
}
:global(.right).leftIcon {
  visibility: hidden;
}

:global(.rightArrow) .icon {
  background: url('data:image/svg+xml;utf8,<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg"><path d="M10.69 6.966a1.5 1.5 0 0 1 2.121-.065l8.714 8.132.107.111c.087.1.16.21.215.326l.01.023a1.009 1.009 0 0 1 .06.148 1.343 1.343 0 0 1 .04.136c.005.02.01.041.013.062.007.029.012.059.016.089l.002.027c.05.412-.072.84-.361 1.168l-.108.11-8.714 8a1.5 1.5 0 0 1-2.146-2.09l.107-.11 7.528-6.913-7.538-7.034-.106-.11a1.5 1.5 0 0 1 .04-2.01z" fill="gray" fill-rule="nonzero"/></svg>');
}

:global(.leftArrow) .icon {
  background: url('data:image/svg+xml;utf8,<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg"><path d="M10.69 6.966a1.5 1.5 0 0 1 2.121-.065l8.714 8.132.107.111c.087.1.16.21.215.326l.01.023a1.009 1.009 0 0 1 .06.148 1.343 1.343 0 0 1 .04.136c.005.02.01.041.013.062.007.029.012.059.016.089l.002.027c.05.412-.072.84-.361 1.168l-.108.11-8.714 8a1.5 1.5 0 0 1-2.146-2.09l.107-.11 7.528-6.913-7.538-7.034-.106-.11a1.5 1.5 0 0 1 .04-2.01z" fill="gray" fill-rule="nonzero"/></svg>');
  transform: rotate(180deg);
}

:global(.refresh) .icon {
  background: url('data:image/svg+xml;utf8,<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg"><path d="M13.883 3.276c.533.415.655 1.16.302 1.72l-.077.11-1.322 1.689c5.303-1.24 10.795 1.715 12.612 6.977 1.939 5.614-1.039 11.727-6.65 13.653-5.61 1.926-11.728-1.062-13.666-6.674a1.304 1.304 0 1 1 2.464-.85c1.468 4.25 6.105 6.515 10.354 5.056a8.117 8.117 0 0 0 5.034-10.334 8.162 8.162 0 0 0-9.55-5.288l1.38 1.078c.532.415.653 1.161.3 1.72l-.076.11a1.306 1.306 0 0 1-1.722.3l-.11-.077-4.01-3.131a1.302 1.302 0 0 1-.301-1.72l.077-.109 3.13-4.005a1.306 1.306 0 0 1 1.831-.225z" fill="gray" fill-rule="nonzero"/></svg>');
}

:global(.rightArrow).btnPrimary .icon {
  background: url('data:image/svg+xml;utf8,<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg"><path d="M10.69 6.966a1.5 1.5 0 0 1 2.121-.065l8.714 8.132.107.111c.087.1.16.21.215.326l.01.023a1.009 1.009 0 0 1 .06.148 1.343 1.343 0 0 1 .04.136c.005.02.01.041.013.062.007.029.012.059.016.089l.002.027c.05.412-.072.84-.361 1.168l-.108.11-8.714 8a1.5 1.5 0 0 1-2.146-2.09l.107-.11 7.528-6.913-7.538-7.034-.106-.11a1.5 1.5 0 0 1 .04-2.01z" fill="white" fill-rule="nonzero"/></svg>');
}

:global(.leftArrow).btnPrimary .icon {
  background: url('data:image/svg+xml;utf8,<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg"><path d="M10.69 6.966a1.5 1.5 0 0 1 2.121-.065l8.714 8.132.107.111c.087.1.16.21.215.326l.01.023a1.009 1.009 0 0 1 .06.148 1.343 1.343 0 0 1 .04.136c.005.02.01.041.013.062.007.029.012.059.016.089l.002.027c.05.412-.072.84-.361 1.168l-.108.11-8.714 8a1.5 1.5 0 0 1-2.146-2.09l.107-.11 7.528-6.913-7.538-7.034-.106-.11a1.5 1.5 0 0 1 .04-2.01z" fill="white" fill-rule="nonzero"/></svg>');
  transform: rotate(180deg);
}

:global(.refresh).btnPrimary .icon {
  background: url('data:image/svg+xml;utf8,<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg"><path d="M13.883 3.276c.533.415.655 1.16.302 1.72l-.077.11-1.322 1.689c5.303-1.24 10.795 1.715 12.612 6.977 1.939 5.614-1.039 11.727-6.65 13.653-5.61 1.926-11.728-1.062-13.666-6.674a1.304 1.304 0 1 1 2.464-.85c1.468 4.25 6.105 6.515 10.354 5.056a8.117 8.117 0 0 0 5.034-10.334 8.162 8.162 0 0 0-9.55-5.288l1.38 1.078c.532.415.653 1.161.3 1.72l-.076.11a1.306 1.306 0 0 1-1.722.3l-.11-.077-4.01-3.131a1.302 1.302 0 0 1-.301-1.72l.077-.109 3.13-4.005a1.306 1.306 0 0 1 1.831-.225z" fill="white" fill-rule="nonzero"/></svg>');
}

:global(.rightArrow).btnNoneBlack .icon {
  background: url('data:image/svg+xml;utf8,<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg"><path d="M10.69 6.966a1.5 1.5 0 0 1 2.121-.065l8.714 8.132.107.111c.087.1.16.21.215.326l.01.023a1.009 1.009 0 0 1 .06.148 1.343 1.343 0 0 1 .04.136c.005.02.01.041.013.062.007.029.012.059.016.089l.002.027c.05.412-.072.84-.361 1.168l-.108.11-8.714 8a1.5 1.5 0 0 1-2.146-2.09l.107-.11 7.528-6.913-7.538-7.034-.106-.11a1.5 1.5 0 0 1 .04-2.01z" fill="black" fill-rule="nonzero"/></svg>');
}

:global(.leftArrow).btnNoneBlack .icon {
  background: url('data:image/svg+xml;utf8,<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg"><path d="M10.69 6.966a1.5 1.5 0 0 1 2.121-.065l8.714 8.132.107.111c.087.1.16.21.215.326l.01.023a1.009 1.009 0 0 1 .06.148 1.343 1.343 0 0 1 .04.136c.005.02.01.041.013.062.007.029.012.059.016.089l.002.027c.05.412-.072.84-.361 1.168l-.108.11-8.714 8a1.5 1.5 0 0 1-2.146-2.09l.107-.11 7.528-6.913-7.538-7.034-.106-.11a1.5 1.5 0 0 1 .04-2.01z" fill="black" fill-rule="nonzero"/></svg>');
  transform: rotate(180deg);
}

:global(.refresh).btnNoneBlack .icon {
  background: url('data:image/svg+xml;utf8,<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg"><path d="M13.883 3.276c.533.415.655 1.16.302 1.72l-.077.11-1.322 1.689c5.303-1.24 10.795 1.715 12.612 6.977 1.939 5.614-1.039 11.727-6.65 13.653-5.61 1.926-11.728-1.062-13.666-6.674a1.304 1.304 0 1 1 2.464-.85c1.468 4.25 6.105 6.515 10.354 5.056a8.117 8.117 0 0 0 5.034-10.334 8.162 8.162 0 0 0-9.55-5.288l1.38 1.078c.532.415.653 1.161.3 1.72l-.076.11a1.306 1.306 0 0 1-1.722.3l-.11-.077-4.01-3.131a1.302 1.302 0 0 1-.301-1.72l.077-.109 3.13-4.005a1.306 1.306 0 0 1 1.831-.225z" fill="black" fill-rule="nonzero"/></svg>');
}

:global(.refresh).btnNone .icon {
  background: url('data:image/svg+xml;utf8,<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg"><path d="M13.883 3.276c.533.415.655 1.16.302 1.72l-.077.11-1.322 1.689c5.303-1.24 10.795 1.715 12.612 6.977 1.939 5.614-1.039 11.727-6.65 13.653-5.61 1.926-11.728-1.062-13.666-6.674a1.304 1.304 0 1 1 2.464-.85c1.468 4.25 6.105 6.515 10.354 5.056a8.117 8.117 0 0 0 5.034-10.334 8.162 8.162 0 0 0-9.55-5.288l1.38 1.078c.532.415.653 1.161.3 1.72l-.076.11a1.306 1.306 0 0 1-1.722.3l-.11-.077-4.01-3.131a1.302 1.302 0 0 1-.301-1.72l.077-.109 3.13-4.005a1.306 1.306 0 0 1 1.831-.225z" fill="white" fill-rule="nonzero"/></svg>');
}

.icon{
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon.noIcon{
  display: none;
}
.icon img{
  max-width: 20px;
  max-height: 20px;
}

/*
.btnPrimary {
    color: #fff;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background: #f86969;
}

.btnPrimary:hover {
    background: #edc3c3;
    color: #f86969;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btnPrimary:active {
    background: #edc3c3;
    color: #f86969;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btnSecondary {
    color: #f86969;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background: #fff;
}

.btnSecondary:hover {
    background: #edc3c3;
    color: #f86969;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btnSecondary:active {
    background: #edc3c3;
    color: #f86969;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
*/

@media only screen and (max-width: 800px) {
  .btn {
    width: 280px;
    height: 45px;

    font-size: 15px;
  }
}
