.row {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  gap: 5px;
}
.row.center {
  justify-content: center;
}
.btnHolder {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  margin-bottom: 25px;
}
.btnHolder button {
  margin: 0 10px;
}
.rightCol {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  float: right;
  width: 390px;
  border-radius: 24px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #eef1f6);
}
:global(.mobile) .rightCol {
  margin-left: 20px;
  margin-right: 20px;
}
.rightCol img {
  width: 100%;
  margin-bottom: 40px;
}
:global(.mobile) .rightCol img {
  margin-bottom: 40px;
}
.nativeness {
  position: relative;
  margin-top: -30px;
  margin-bottom: 15px;
  font-size: 13px;
  font-weight: normal;
  color: var(--neutral-colors-black);
}
.leftCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  float: right;
  width: 200px;
  border-radius: 24px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #eef1f6);
  position: relative;
}

:global(.mobile) .leftCol {
  position: absolute;
  top: -15px;
  width: 85%;
  background-image: linear-gradient(to bottom, #fff, #eef1f6);
  flex-direction: row-reverse;
  padding: 20px;
  line-height: 1.2;
  transform: translateY(-100%);
}
:global(.mobile) .advice {
  padding-right: 15px;
}
:global(.mobile) .leftCol :global(.cf-audioPlayer-controls) {
  margin: 0 auto;
}

.chatterIcon {
  width: 42px;
  display: block;
  position: absolute;
  bottom: -32px;
  left: 20px;
}
.chatterIcon img {
  width: 100%;
}
.notice {
  display: block;
  margin: 20px auto;
  font-size: 13px;
  font-weight: 300;
  text-align: center;
  color: var(--neutral-colors-black);
}
.grayPlayer {
  /* filter: grayscale(1); */
  opacity: 0.7;
  margin-right: auto;
}
.grayPlayer :global(.control-button) {
  box-shadow: 0 0 !important;
  border: 2px solid #9b9b9b;
}
.audioBtnHolder > div {
  padding: 0;
}
.audioBtnHolder > div > div {
  margin: 0 5px !important;
}
.audioBtnHolder {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: -50px;
}
.audioBtnHolder :global(.cf-audioPlayer-label) {
  margin: 0;
}

:global(.mobile) .btnHolder {
  width: 100vw;
  padding: 0 12px;
  margin-top: 30px;
}

:global(.mobile) .smartCoachModal .btnHolder {
  width: 100vw;
  padding: 0 12px;
  margin-top: 60px;
  flex-wrap: wrap;
}

:global(.mobile) .btnHolder button {
  margin: 7px;
}

:global(.fade.modal.show) {
  display: flex !important;
}

:global(.modal-dialog).smartCoachModal {
  display: flex !important;
  height: 75vh;
}

:global(.modal) {
  padding: 0;
}
.smartCoachModal {
  width: 100%;
  border-radius: 32px 32px 0 0;
  position: absolute;
  bottom: 0;
  height: auto;
  margin: 0;
}

.modal {
  z-index: 999;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
}

.modalCover {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.modalContainer {
  position: relative;
  z-index: 1;
  /* width: 480px;
  height: 380px; */
  padding: 20px 50px 10px;
  border-radius: 32px;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.25);
  background-image: linear-gradient(132deg, var(--neutral-colors-white), #f8f9fa 98%);
}

.modalContainer .btnHolder {
  margin-top: 60px;
}

:global(.mobile) .modalContainer {
  width: 100%;
  border-radius: 32px 32px 0 0;
  position: fixed;
  bottom: 0;
  height: auto;
  padding: 20px 0;
}

:global(.mobile) .modalContainer > div {
  display: flex;
  flex-direction: column;
}

.modalContentBtn {
  width: 106px;
  height: 100px;
  /* padding: 30px; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 24px;
  border: 0;
  /* box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(144deg, var(--neutral-colors-white), #f8f9fa 101%); */
}

.modalContentBtn div {
  width: 100%;
}

.audioPlayer.modalContentBtn {
}

.audioPlayer.modalContentBtn img {
  width: 40px !important;
  height: auto;
  object-fit: contain;
  margin: 0;
}

.audioPlayer.modalContentBtn > div {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 130px;
}

.audioPlayer.modalContentBtn div {
  display: flex !important;
  position: relative !important;
  justify-content: center !important;
}

.audioPlayer.modalContentBtn div img {
  margin: 6px 0 0 !important;
  height: 30px !important;
}

.audioPlayer.modalContentBtn span {
  display: block;
  width: 50%;
  text-align: center;
  font-size: 12px;
  margin: auto;
}

.modalFooter {
  width: 100%;
  display: flex;
  justify-content: space-around;
  position: absolute;
  bottom: 0;
  margin: 30px 0;
  padding: 0 20px;
}

:global(.mobile) .modalFooter {
  flex-wrap: wrap;
  position: relative;
  margin-top: 30px;
}

:global(.mobile) .modalFooter button {
  margin: 7px;
}

.smartCoachModal :global(.modal-content) {
  margin: auto;
}

.loading {
  height: 50px;
  width: 200px;
  object-fit: cover;
  margin: 15px auto -5px;
}
.resultBox {
  display: flex;
  justify-content: center;
  width: 350px;
  height: 150px;
  margin-bottom: 30px;
}
.resultColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin: 3px;
  text-align: center;
  width: 20px;
}
.resultBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  height: 100%;
  width: 10px;
  border-radius: 10px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  background-color: #eeeff1;
  position: relative;
}
.coloredLine {
  background-color: #e60000;
  border-radius: 10px;
  display: block;
  min-height: 10px;
  width: 100%;
  bottom: 0px;
  position: absolute;
}
.coloredLine.color1 {
  background-color: #e60000;
}
.coloredLine.color2 {
  background-color: #fd5f00;
}
.coloredLine.color3 {
  background-color: #fdd000;
}
.coloredLine.color4 {
  background-color: #35cd1e;
}
.coloredLine.color5 {
  background-color: #007800;
}
.resultLetter {
  text-transform: uppercase;
  font-size: 18px;
  margin-top: 5px;
}
