.modal {
    z-index: 9999;
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modalCover {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  }
  
  .modal :global(.modal-content) {
    border: 0 solid;
    background-color: transparent;
    box-shadow: 0 0;
  }

  :global(.mobile).modal :global(.modal-content) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .modal :global(.modal-dialog) {
    position: relative;
    z-index: 1;
    width: 480px;
    min-height: 250px;
    padding-bottom: 15px;
    border-radius: 32px;
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.25);
    background-image: linear-gradient(132deg, var(--neutral-colors-white), #f8f9fa 98%);
    margin-top: -15vh;
  }
  
  :global(.mobile).modal :global(.modal-dialog) {
    width: 100%;
    border-radius: 32px 32px 0 0;
    bottom: 0px;
    position: fixed;
    z-index: 9999;
    height: auto;
    margin: 0;
  }
  
  .modal :global(.modal-footer) {
    display: flex;
    flex-wrap: nowrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    bottom: 0;
    margin: 20px 0 10px;
    padding: 0;
    border-top: 0px solid #dee2e6;
  }
  
  .modal :global(.modal-footer) button {
    margin: 0 5px;
  }

  :global(.mobile).modal :global(.modal-footer) {
    flex-wrap: wrap;
    margin: 10px 0;
    position: relative;
  }
  
  :global(.mobile).modal :global(.modal-footer) button {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .modal :global(.modal-header){
    margin-bottom: -40px;
    border-bottom: 0;
    color: #000;
    padding: 20px 30px;
  }
  .modal :global(.modal-content .modal-header .modal-title) {
    font-family: Metropolis;
    font-size: 16px;
    font-weight: 500;
  }
  .modal :global(.modal-header .btn-close){
    padding-top: 0px;
    margin-top: -10px;
  }
  
  .modal :global(.modal-title){
    color: #000;
  }

  .modal :global(.modal-body) {
    text-align: left;
    width: 419px;
    min-height: 100px;
    padding: 15px;
    border-radius: 24px;
    margin: 40px auto 0;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #eef1f6);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 400;
}

.modal :global(.modal-body) p {
    justify-content: center;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.4;
    margin-bottom: 0;
    color: #000;
}

  :global(.mobile).modal :global(.modal-body) {
    flex-direction: column;
    width: 80%;
    margin-top: 25px;
  }
  
  .modalContentIcon {
    margin-right: 15px;
  }
  
  :global(.mobile).modal .modalContentIcon {
    margin-right: 0;
    margin-top: 10px;
    margin-bottom: 15px;
  }
   
  .contentBtnHolder {
    width: 240px;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin: -60px auto 0;
  }
  
  .modalContentBtn {
    width: 106px;
    height: 120px;
    /* padding: 30px; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-radius: 24px;
    border: 0;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(144deg, var(--neutral-colors-white), #f8f9fa 101%);
  }