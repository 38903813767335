.separator{
    width: 100%;
    margin: 20px 0;
}
.separatorTitle{
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    color: #3484d8;
}
.separatorTitle span{
    padding: 0 14px;
    white-space: nowrap;
}
.separatorTitle .line{
    width: 100%;
    padding: 0;
    height: 2px;
    background-color: #3484d8;
}
.separatorSubtitle{
    color: #535353;
    width: 80%;
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-top: 5px;
}