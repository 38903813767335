.cfModuleBody {
  max-width: 700px;
  margin: 0 auto;
  padding: 30px 25px 80px;
  height: 80%;
}

.cfModuleContent {
  /* padding: 0 10vw; */
  padding-bottom: 80px;
}

.cfDictionaryContent {
  flex: 1;
  padding: 0 2vw;
}

@media (min-width: 1200px) {
  :global(.introduction-module-list .container) {
    max-width: 1400px;
  }
}
