@import url("https://fonts.googleapis.com/css?family=Permanent+Marker|Rock+Salt");

.App {
  font-family: sans-serif;
  text-align: center;
}

.player {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  
  .controls {
    flex-grow: 1;
    margin: 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    
    .controlButton {
      flex: 0 0 auto;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      border-radius: 100px;
      height: 52px;
      width: 52px;
      background-color: #fff;
      cursor: pointer;
      display: flex;
      justify-content: center;
      margin-right: 10px;
      
      img {
        width: 15px;
      }
    }  
  }
  
  .label {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 1.5;
    color: var(--neutral-colors-black);
    margin-left: 15px;

    .duration {
      font-size: 12px;
      font-weight: 300;
      line-height: 1.33;
      color: var(--neutral-colors-gray-50);
    }
  }
  :global(.button_audio) {
    width: 156px;
    height: 62px;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    border-radius: 24px;
    
    .controlButton {
      box-shadow: 0 0 0;
      position: absolute;
      width: 100%;
      width: 100%;
      border-radius: 24px;
      z-index: 0;
      background-color: transparent;
      display: block;

      img{
        height: 100%;
        width: 40px;
        object-fit: contain;
        margin-left: 10px;
      }
    }
    .label {
      font-size: 12px;
      // z-index: 1;
    }
  }

  .noBar {
    // margin: unset;

    .controlButton {
      margin-right: unset;
    }
  }

  .bar {
    user-select: none;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    position: relative;
    margin-left: 15px;

    .time {
      font-size: 11px;
      font-weight: 300;
      line-height: 1.45;
      text-align: right;
      color: var(--neutral-colors-gray-50);
      position: absolute;
      right: 0;
      top: 12px;
    }

    .progress {
      flex: 1;
      border-radius: 5px;
      height: 3px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .knob {
        position: relative;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: var(--chatter-fox);
      }
    }
  }


  @media screen and (max-width: 800px) {
    flex-direction: column;
    padding: 8px 0;

    .controls {
      width: 100%;

      img {
        width: 37px;
        // margin-right: 4px;
      }
    }

    .bar {
      width: 90%;

      .time {
        font-size: 10px;
      }

      .progress {
        // margin: 0 20px;
        height: 3px;

        .knob {
          height: 11px;
          width: 11px;
        }
      }
    }
  }

  @media screen and (max-width: 500px) {}
}

:global(.large).player{
  .controlButton{
    width: 62px;
    height: 62px;

    img{
      width: 20px;
    }

  }
}

:global(.lite).player{
  border-radius: 50px;
  overflow: hidden;
  padding: 0;
  margin-bottom: 10px;
  
  .controls{
    display: block;
    margin: auto;
  }
  
  .controlButton{
    display: flex;
    width: 34px;
    height: 34px;
    background-color: rgba(0,0,0,.3);
    box-shadow: 0 0 0;
  }
  
}
:global(.capsule).player {
  .controls{
    display: block;
  }
  .controlButton{
    position: relative;
    width: 76px;
    height: 106px;
    margin: 0 auto;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.25), inset 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    background-image: linear-gradient(149deg, var(--neutral-colors-white), #f8f9fa 103%);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .controlButton img{
    width: auto;
    height: 30px;
    margin: auto;
  }
  
}