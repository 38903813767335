.container {
  position: fixed;
  height: 32px;
  width: 100%;
  z-index: 2;
  background-color: var(--mainBgColor) !important;
  background-position: 28px -74px !important;
}

.header {
  width: 817px;
  height: 66px;
  position: fixed;
  left: calc(50% - 366px);
  margin-top: 32px;
  padding: 18px 17px 16px 17px;
  border-radius: 16px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--neutral-colors-white);
  display: flex;
  justify-content: flex-start;
  flex-flow: row nowrap;
  align-items: center;
  z-index: 999;
}

.hide{
  display: none;
}

:global(.mobile) .header {
  width: 100%;
  margin-top: 0;
  border-radius: unset;
  left: 0;
}

.content {
  width: 100%;
  transition: 300ms ease all;
  background: inherit;
  margin-left: 0;

  display: flex;
  align-items: center;
}

.content.withClose {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.content ul {
  margin: 0;
  margin-left: 0px;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
}

.topMenu li {
  margin-left: 20px;
}
:global(.mobile) .topMenu li {
  margin-left: 15px;
}

.topMenu .profilePic {
  width: 47px;
}

.title {
  font-family: Metropolis;
  font-size: 16px !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--neutral-colors-black);
  margin: unset;
}

:global(.mobile) .title{
  margin-top: 6px;
  margin-top: 8px;
}

.logo {
  margin-right: 30px;
  width: 4rem;
  cursor: pointer;
}

.back {
  font-size: 22px;
  margin-right: 20px;
  cursor: pointer;
}

.titleCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}

.show {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

.creditsCount{
  color: #000;
  font-weight: 500;
}