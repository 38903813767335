.title {
  margin-bottom: 100px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: var(--neutral-colors-black);
}

.circle {
  display: block;
  width: 48px;
  height: 48px;
  padding: 13px 17px;
  border-radius: 50%;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--neutral-colors-white);
  align-self: flex-start;
  cursor: pointer;
  position: absolute;
  top: 0;
}

.circle svg {
  vertical-align: -5px;
  font-size: 18px;
}

.form {
}

.form button {
  margin: 32px auto 10px;
}

.tip {
  text-align: center;
  width: 285px;
  margin: 16px auto;
  font-size: 14px;
  color: var(--neutral-colors-black);
}
