.cardsHolder{
    min-width: 485px;
  }

  :global(.mobile) .cardsHolder{
    min-width: auto;
  }
  
  .cardsHolder :global(.progressBar){
    margin-bottom: 35px;
  }

  .body {
    flex: 1 0 auto;
    display: table;
    text-align: center;
    width: 405px;
    height: 230px;
    border-radius: 20px;
    color: #fff;
    font-size: 32px;
    font-weight: 600;
    margin: 10px auto 0;
    background: url('../../assets/images/assessment/waveBgWhite.svg') #fa6400 center;
    background-size: 40%;
    line-height: 1.3;
    padding: 30px 30px;
  }
  
  :global(.mobile) .body{
    font-size: 28px;
  }

  .body.center{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 60px;
  }

  .topText, .bottomText{
    margin: 15px auto;
  font-size: 13px;
  font-weight: 300;
  text-align: center;
  color: var(--neutral-colors-black);
  }

  .topText{
    margin: 0px auto 25px;
  }
  
  .sentence {
    font-size: 21px;
    text-align: left;
    align-items: baseline;
    display: flex;
    padding: 20px;
  }
  
  .body span {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
  }

  .playCard :global(.progress){
    width: 100%;
    height: .7rem;
    overflow: unset;
    background-color: #d8d8d8 !important;
  }


  .playCard :global(.progress-bar) {
    overflow: inherit;
    box-shadow: none !important;
}

.audioPlayerLite {
    width: 34px;
    height: 34px;
    background-color: rgba(0,0,0,.3);
    border-radius: 50px;
    overflow: hidden;
    padding: 0;
    margin-bottom: 10px;
}

.footer{

}
  
.footerBox {
  width: 100%;
  height: calc(125px + (197 - 125) * ((100vw - 800px) / (1920 - 800)));
  margin: 0 auto;
  padding: 25px;
  border-radius: 30px;
  background-color: #efefef;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
}

.soundBtn {
  width: 76px;
  height: 106px;
  background-color: #fff;
  margin: -50px auto 0;
  box-shadow: 0 0 5px rgba(0,0,0,.3);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.unVisible{
  visibility: hidden;
}

.soundBtn img {
  /* height: calc(75px + (114 - 75) * ((100vw - 800px) / (1920 - 800))); */
  /* height: 50px !important; */
  cursor: pointer;
  margin: auto !important;
  width: 75%;
}

@media only screen and (max-width: 820px) {
  .body {
    width: 80vw;
    height: 310px;
    border-radius: 20px;
  }
  .cardsHolder :global(.progressBar) {
    max-width: 85vw;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding-top: 10px;
    margin-bottom: 40px;
  }
}
