.container {
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: radial-gradient(circle, rgba(245, 161, 83, 0.3) 0%, rgba(245, 161, 83, 0) 40%);
  background-repeat: no-repeat;
  background-size: 111% 78%;
  background-position: 79% -48%;
  padding: 50px 0;
}

@media screen and (max-width: 500px) {
  .container {
    background-size: 300% 60%;
    background-position: 41% -39%;
  }
}

.content {
  max-width: 90%;
  width: 520px;
  margin: 0px auto;
  text-align: center;
  /* font-family: Gilroy; */
  font-style: normal;
  position: relative;
}

.content a:hover {
  color: #c6301c;
}
.logo {
  width: 80px;
  height: 80px;
  /* margin-top: 120px; */
}

.content h1 {
  font-weight: 900;
  font-size: 50px;
  color: black;
}

.content h1 :global(.cf-t-highlight) {
  color: #f5a153;
}

.content :global(.cf-t-redLine) {
  width: 90px;
  height: 5px;
  margin: 0 auto;
  margin-top: 16px;

  background: #c62827;
  border-radius: 4px;
}

.content :global(.googleLogin) {
  width: 100%;
  height: 64px;
  margin: 30px 0 0;
  border-radius: 20px !important;
  background-image: var(--neutral-colors-white);
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.03) !important;
  position: relative;
  display: flex;
  justify-content: center;
  border-color: #c4c4c4 #f5a153 #f5a153 !important;
}

/* .content :global(.googleLogin)::before {
  position: absolute;
  top: -1px;
  bottom: -2px;
  left: -1px;
  right: -1px;
  background: linear-gradient(180deg, #c4c4c4 -0.23%, #f5a153 37.1%);
  content: '';
  z-index: -2;
  border-radius: 20px;
} */

.content :global(.googleLogin)::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--neutral-colors-white);
  content: '';
  z-index: -1;
  border-radius: 20px;
}

.content :global(.googleLogin) div {
  background: none !important;
}

.content :global(.cf-t-horizontalLine) {
  width: 100%;
  height: 1px;
  margin: 30px 0 40px;
  text-align: center;
}

.content :global(.cf-t-horizontalLine) span {
  display: flex;
  width: 100%;
  font-size: 12px;
  padding: 0 8px;
  /* background-color: #f1f1f5; */
  color: rgba(0, 0, 0, 0.4);
  align-items: center;
}

.content :global(.form-check-input) {
  width: 16px;
  height: 16px;
  border-radius: 50px !important;
  border-color: #c4c4c4 #f5a153 #f5a153;
  margin-top: 2px;
}

.content :global(.form-check-input:focus) {
  box-shadow: 0 0 0 0.25rem rgba(245, 161, 83, 0.35) !important;
  border-color: #f5a153 !important;
}

.content :global(.form-check-input:checked) {
  background-color: #f5a153 !important;
  border-color: #f5a153 !important;
}

.content :global(.form-check-label) {
  /* font-family: Gilroy; */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: #2c2c2c;
}

.content :global(.cf-t-horizontalLine) span:after,
.content :global(.cf-t-horizontalLine) span:before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--neutral-colors-gray-20);
  margin: 0 10px;
}

.content :global(.cf-t-form) :global(.form-floating) > label {
  padding: 1.3rem 1.75rem;
}

.content :global(.cf-t-form) :global(.form-control) {
  width: 100%;
  height: 64px;
  margin: 16px 0;
  padding: 20px 21px 20px 36px;
  border-radius: 20px;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.03);
  background-color: var(--neutral-colors-white);
  border-color: #c4c4c4 #f5a153 #f5a153;
}

/* .content :global(.cf-t-form) :global(.form-floating)::before {
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  background: linear-gradient(180deg, #c4c4c4 -0.23%, #f5a153 37.1%);
  content: '';
  z-index: -1;
  border-radius: 20px;
} */

:global(.form-floating) label {
  font-size: 18px;
}

.content :global(.cf-t-form) :global(.form-floating) > :global(.form-control):focus,
:global(.form-floating) > :global(.form-control):not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
  padding-left: 1.5rem;
}

.content :global(.cf-t-form) .passwordInput {
  padding-right: 45px;
}

.content :global(.cf-t-form) :global(.eye-icon) {
  position: absolute;
  top: 20px;
  right: 24px;
  cursor: pointer;
  display: block;
  width: 24px;
  height: 24px;
}

.content :global(.cf-t-form) .link {
  cursor: pointer;
  display: block;
  text-align: center;
  margin-top: 27px;
}

.content :global(.cf-t-form) :global(.invalid-feedback) {
  margin-top: -11px;
  margin-left: 25px;
  text-align: left;
}

.content :global(.cf-t-button) {
  margin: 30px auto !important;
}
