.logo {
  margin-bottom: 100px;
}

.form {
}

.alternatives {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  align-items: center;
}

.link {
  /* font-family: Gilroy; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #f5a153;
  cursor: pointer;
}

.register {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  color: var(--neutral-colors-black);
}

.register a {
  margin-left: 4px;
  font-size: 14px;
  color: var(--chatter-fox);
  text-decoration: none;
}
