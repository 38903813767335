@media only screen and (min-width: 800px) {
  .cf-initial-assessment-header .navbar-collapse .navbar-nav {
    display: none !important;
  }
}

:root {
  --neutral-colors-black: #000;
  --neutral-colors-gray-20: #c1c7cf;
  --neutral-colors-white: #fff;
  --chatter-fox: #fa6400;
  --chatter-green: #3aa483;
  --ruby: #e02020;
  --neutral-colors-gray-50: #777b80;
  --citrine: #f7b500;
  --emerald: #6dd400;
  --neutral-colors-gray-10: #dcdce9;
  --apatite: #32c5ff;
  --neutral-colors-gray-75: #484a4d;
  --sapphire: #0091ff;
  --iolite: #6236ff;
  --mainBgColor: #f1f1f5;
}

* {
  box-sizing: border-box;
}

body {
  background-color: var(--mainBgColor) !important;
  font-family: 'Metropolis' !important;
  overscroll-behavior-y: contain;
}
.mainContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* width: 817px; */
  margin: auto;
}
.header {
  width: 100%;
  height: 66px;
  margin: 0 auto 40px auto;
  padding: 17px 17px 16px 8px;
  border-radius: 16px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--neutral-colors-white);
}
.contentContainerHolder {
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: baseline;
  position: relative;
  /* align-items: center; */
  padding-top: 120px;
}
.noHeader .contentContainerHolder {
  padding-top: 60px;
}
:global(.mobile) .contentContainerHolder {
  padding-top: 70px;
}
.contentContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* height: calc(100vh - 64px); */
  min-height: 500px;
  margin: 0 auto;
  padding: 30px 60px;
  border-radius: 16px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(129deg, var(--neutral-colors-white), #f8f9fa 96%);
  background-color: var(--neutral-colors-white);
}
.footer {
  display: flex;
  justify-content: space-between;
  color: #8f9399;
  font-size: 12px;
  width: 817px;
  height: 64px;
  padding: 24px;
  border-radius: 0;
  margin: 0 auto;
}
.footer a {
  color: #8f9399 !important;
  font-size: 12px !important;
  font-family: 'Metropolis' !important;
  font-weight: normal;
}

:global(button:disabled) {
  color: rgba(255, 255, 255, 0.8) !important;
  filter: grayscale(0.7);
}
:global(.spinner-border) {
  margin-right: 10px !important;
  margin-left: -25px;
  margin-bottom: 0 !important;
}
@media only screen and (max-width: 820px) {
  body {
    background-color: var(--neutral-colors-white) !important;
  }
  .mainContainer {
    width: 100vw;
  }
  .contentContainer {
    padding: 30px 60px;
    box-shadow: none;
  }
  .footer {
    justify-content: center;
    padding: 10px;
    height: 36px;
    width: 100%;
  }
  .footer a {
    display: none;
  }
}
