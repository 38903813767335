.mainForm {
  margin-top: unset;
  font-family: PT Sans;
  font-size: 16px;
  padding: 10px 30px 50px;
}
:global(a, .btn, button, h1, h2, h3, h4, h5, h6) {
  font-family: PT Sans;
}
.mainForm label {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}
.mainForm li > * {
  display: inline-block;
  color: #333;
  font-size: 16px !important;
  font-weight: normal;
  line-height: 20px;
}
.panel {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #000;
  border-top: 0;
  border-right: 0;
  border-radius: 0 0 0 10px;
  padding: 0 15px 30px;
  margin: 40px -15px;
}
.mainForm input {
  border-radius: 0;
  border-color: #808080;
}

.mainForm :global(.StripeElement) {
  border-radius: 0;
  border-color: #808080;
  display: flex;
  padding: 0 0.8rem;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.mainForm h2 {
  font-family: Roboto Slab;
  font-weight: bold;
  color: #004c6d;
  font-size: 20px;
}

.panel .titleBullet {
  width: 20px;
  float: left;
  margin: 6px 5px 0 -30px;
}

.panel .billHeading {
  margin: -12px 0 0 0;
}

.paymentMethodBtn {
}

.paymentMethodBtn img {
  height: 28px;
  margin-right: 7px;
}

.panel :global(.payment-method .btn) {
  border: 1px solid #4d4d4d;
  border-radius: 0;
  padding: 10px;
  margin-right: 25px;
  opacity: 0.7;
  transition: all 0.3s;
  width: 165px;
  position: relative;
}
.panel :global(.payment-method .btn:hover) {
  opacity: 1;
}
.panel :global(.payment-method .btn.active) {
  opacity: 1;
}
.panel :global(.payment-method .btn) .activeCheck {
  display: none;
}
.panel :global(.payment-method .btn.active) .activeCheck {
  display: block;
  position: absolute;
  right: -18px;
  bottom: -10px;
}

.panel :global(.nav-tabs) {
  border-bottom: 0px solid #7e8fa3;
  border-bottom-color: rgb(126, 143, 163);
  margin: 7px 0 !important;
}

.totalContainer {
  display: flex;
  align-items: center;
}

.hLine {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #2e333a;
}

.mainForm :global(.payment-total) {
  text-align: center;
  white-space: nowrap;
  padding: 0 15px;
  font-size: 24px;
}
.mainForm :global(.payment-total) br {
  display: none;
}
.mainForm :global(.trial_plan) {
  font-size: 20px !important;
}

.payBtn {
  width: 100% !important;
  background-color: #f15a24;
  border: 1px solid #ed1c24;
  color: #fff;
  line-height: 60px;
  font-size: 18px;
  font-weight: normal;
  font-family: PT Sans;
  transition: all 0.3s;
  margin-right: 40px;
  white-space: nowrap;
  padding: 0 30px;
}

.payBtn:hover,
.payBtn:focus {
  background-color: #ed1c24;
  border: 1px solid #f15a24;
}

.paypalBtn {
  margin: auto;
  width: 300px;
}

.btnBox {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.termBox {
  color: #4d4d4d;
  font-size: 14px;
}

.termBox a {
  color: #f15a24;
}
.termBox a:hover,
.termBox a:focus {
  color: #ed1c24;
}

.sideBox {
  border-radius: 10px 10px 0 0;
  padding: 0;
  margin-bottom: 20px;
  border: 1px solid #7fcdee;
  background-color: #f5fbff;
  overflow: hidden;
}

.sideBoxBottom {
  border-radius: 0 0 10px 10px;
}

.sideBox h2 {
  background-color: #7fcdee;
  min-height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px !important;
  text-align: center;
  font-size: 20px !important;
}

.sideBox ul {
  margin: 0;
  padding: 20px 25px 20px 45px;
}

.sideBox ul li {
  position: relative;
  margin-bottom: 10px;
}

.sideBox ul li:last-child {
  margin-bottom: 0;
}

.sideBox ul li::before {
  content: '';
  position: absolute;
  background-size: 100% 100%;
  width: 16px;
  height: 16px;
  left: -25px;
  top: 3px;
}
.header {
  display: flex;
  width: 100%;
  margin: 20px 0 10px;
  align-items: center;
}

.logo {
  height: 60px;
  margin-right: 30px;
}

.headerTitle {
  font-family: Raleway;
  font-size: 35px;
  font-weight: 100;
  margin-top: 9px;
}

.secureNoteBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
}

.secureNoteBox .title {
  line-height: 1;
  margin-right: 10px;
}
.secureNoteBox .bigLine {
  font-size: 111%;
  letter-spacing: 1px;
}
.secureNoteBox .secureIcons {
  height: 40px;
}

@media screen and (max-width: 530px) {
  .logo {
    width: 65px;
    object-fit: cover;
    object-position: left;
    margin-right: 20px;
  }
  .headerTitle {
    font-size: 32px;
    margin-top: 5px;
  }
  .panel :global(.payment-method .btn) {
    width: 100%;
    margin: 20px 0 0;
  }
  .panel :global(.nav-tabs .nav-tabs) {
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    margin: auto !important;
  }
  .mainForm :global(.payment-total) {
    white-space: normal;
    padding: 0;
    width: 100%;
  }
  .hLine {
    display: none;
  }
  .btnBox {
    flex-direction: column;
  }
  .payBtn {
    margin: 0 auto 15px;
    width: 100% !important;
    max-width: 300px;
  }
  .termBox {
    text-align: center;
    margin: 0;
  }
  .secureNoteBox {
    flex-direction: column;
  }
  .secureNoteBox .title br {
    display: none;
  }
  .secureNoteBox .bigLine {
    font-size: 100%;
    letter-spacing: 0;
    margin-right: 5px;
  }
  .secureNoteBox .secureIcons {
    margin-top: 10px;
    width: 100%;
    height: auto;
    max-width: 350px;
  }
  .secureNoteBox .title {
    margin: 0;
    font-weight: bold;
    font-size: 18px;
  }
  .mainForm :global(.payment-total) br {
    display: block;
  }
}
/* .mainForm .panel h2::before {
    content: "";
    background-image: url('../../../../../public/images/titleArrow.svg');
    display: block;
    width: 25px;
    height: 25px;
    border: 1px solid;
    border-radius: 50px;
    float: left;
    margin-right: 10px;
    margin-left: -31px;
} */
