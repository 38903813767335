.content {
    max-width: 1110px;
    width: 100%;
    position: relative;
    margin: 0 auto;

    text-align: center;
}

.title {
    font-family: 'Lato';
    font-size: 30px;
    font-weight: bold;
    color: #F86969;
}

.body {
    text-align: left;
    line-height: 52px;
    margin-bottom: 45px;

    font-family: 'Lato';
    font-size: 20px;
    font-weight: normal;
}

.result {

}

.result .imageResult {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.imageResult .image {
    display: flex;
    flex-direction: row;
    justify-content: center;

    margin-top: 24px;
}

.imageResult .image .imageBox {
    width: 494px;
    height: 113px;
    border: 2px solid #F3684F;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 11px;
    text-align: center;
    margin-right: 60px;
}

.imageResult .image .imageBox img {
    height: 100%;
}

.imageResult .image .audioPlayer img {
    width: 64px;
}

.result > span {
    display: inline-block;
    margin-top: 24px;
}

.result .audioResult {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.audioResult .audio {
    margin-top: 30px;
}

.audioResult .audio .icon {
    width: 115px;
    height: 115px;
    border: 7px solid #EFEFEF;
    border-radius: 50%;
    overflow: hidden;
    margin-left: -38px;
    margin-bottom: -80px;
    position: relative; 
    z-index: 1;
}

.audioResult .audio .icon img {
    width: 245px;
    position: absolute;
    right: -23px;
}

.audioResult .audio .player {
    max-width: 1110px;
    width: 100%;
    height: 136px;
    border-radius: 30px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    background-color: #EFEFEF;
    padding-left: 122px;
    padding-right: 69px;
}

@media only screen and (max-width: 800px) {
    .content {
        width: 302px;
    }

    .title {
        font-size: 17px;
    }

    .body {
        line-height: 20px;
        margin-top: 20px;
        margin-bottom: 32px;
    
        font-size: 15px;
    }

    .imageResult .image {
        margin-top: 24px;
    }

    .imageResult .image .imageBox {
        height: 97px;
        margin-right: 38px;
    }

    .imageResult .image .audioPlayer img {
        width: 50px;
    }

    .result > span {
        margin-top: 17px;
    }

    .audioResult .audio {
        margin-top: 10px;
    }
    
    .audioResult .audio .icon {
        width: 57px;
        height: 57px;
        margin-left: -19px;
        margin-bottom: -39px;
    }

    .audioResult .audio .icon img {
        width: 102px;
        right: -9px;
    }
    
    .audioResult .audio .player {
        height: 53px;
        border-radius: 11px;
        padding-left: 53px;
        padding-right: 18px;
    }
}