.mtSwitchHolder{
  position: relative;
  width: 100%;
  min-width: 100px;
  min-height: 40px;
  background-color: #dcdce9;
  border: 2px solid #dcdce9;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(87, 87, 87);
  text-align: center;
}
:global(.mtSwitch-btn){
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 40px;
  background-color: #fff;
  border-radius: 100px;
  cursor: pointer;
  color: #000;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
}

:global(.mtSwitch-backTxt){
  cursor: pointer;
}