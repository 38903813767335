.bannerA {
    width: 100%;
    height: 102px;
    background: linear-gradient(90deg, rgba(238,227,135,1) 0%, rgba(253,162,135,1) 14%, rgba(253,150,162,1) 32%, rgba(250,131,203,1) 49%, rgba(185,132,232,1) 67%, rgba(125,145,221,1) 84%, rgba(93,178,184,1) 100%);
    border-radius: 8px;
    color: #fff;
    display: table;
    cursor: pointer;
    margin-bottom: 30px;
}

.bannerA .text {
    font-family: 'Lato';
    font-weight: bold;
    font-size: 25px;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
}

.bannerA .button {
    font-size: 20px;
    display: table-cell;
    vertical-align: middle;
    position: relative;
    right: 15px;
}

.bannerB {
    width: 100%;
    height: 174px;
    background-color: #fff;
    border: 3px solid #FBFBFB;
    border-radius: 11px;
    box-shadow: inset 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.16);

    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    cursor: pointer;
    margin-bottom: 30px;
}

.bannerB img {
    height: calc(151px + (191 - 151) * ((100vw - 800px) / (1920 - 800)));
    margin: calc(0px + (-25) * ((100vw - 800px) / (1920 - 800))) 0 0 calc(5px + (77 - 5) * ((100vw - 800px) / (1920 - 800)));
}

.bannerB .text {
    font-family: 'Lato';
    font-weight: bold;
    font-size: calc(15px + (25 - 15) * ((100vw - 800px) / (1920 - 800)));
    text-align: center;
    line-height: 6vh;
    align-self: center;
}

.bannerB .boo {
    font-family: 'Dancing Script';
    font-size: calc(65px + (110 - 65) * ((100vw - 800px) / (1920 - 800)));
    color: #CE3587;
    white-space: nowrap;
    align-self: center;
}

.bannerC {
    width: 100%;
    background: linear-gradient(90deg, rgba(238,227,135,1) 0%, rgba(253,162,135,1) 14%, rgba(253,150,162,1) 32%, rgba(250,131,203,1) 49%, rgba(185,132,232,1) 67%, rgba(125,145,221,1) 84%, rgba(93,178,184,1) 100%);
    border-radius: 1vw;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 30px;
}

.bannerC img {
    margin-top: 0.1vw;
    margin-bottom: -0.328vw;
    height: 7vw;
    align-self: end;
}

.bannerC .text {
    text-align: center;
    align-self: center;
}

.bannerC .text h1 {
    color: #fff;
    font-family: 'Lato';
    font-size: 1.146vw !important;
    font-weight: bold;
    margin-bottom: unset;
}

.bannerC .text p {
    color: #fff;
    font-family: 'Lato';
    font-size: 1.667vw !important;
    font-weight: bold;
    margin-bottom: unset;
    line-height: unset;
}

.bannerC .btn {
    height: min-content;
    background-color: #fff;
    padding: 0.521vw 1.667vw;
    border-radius: 0.208vw;
    color: #FB5014;
    font-family: 'Lato';
    font-size: 0.833vw;
    font-weight: bold;
    align-self: center;
    cursor: pointer;
}

.bannerC .btn:hover {
    background-color: #FB5014;
    color: #fff;
}


.subscribeBanner{
    display: flex;
    padding: 20px 39px 25px 36px;
    border-radius: 15px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
    background-image: linear-gradient(to bottom, #bbc8d4 -5%, var(--neutral-colors-white) 37%);
    margin: 16px 0;
  }
  :global(.mobile) .subscribeBanner{
      flex-direction: column;
      text-align: center;
  }
  .subscribeBanner .rightCol{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 40%;
      margin-left: auto;
    }
:global(.mobile) .subscribeBanner .rightCol{
    margin: 20px auto 0;
    width: 100%;
}
:global(.mobile) .subscribeBanner .rightCol button{
    width: 100%;
}
  .subscribeBanner .subTitle {
    font-size: 22px;
    font-weight: 300;
    line-height: 1;
    margin-top: 5px;
    color: var(--chatter-fox);
  }
  .subscribeBanner .title {
    font-size: 30px;
    font-weight: bold;
    color: var(--chatter-fox);
  }
  .subscribeBanner .text {
    font-size: 14px;
    font-weight: 300;
  }

@media only screen and (max-width: 800px) {
    .bannerA {
        width: unset;
        height: 57px;
        margin: 0 18px;
        padding: 5px 10px;
        border-radius: 8px;
        display: block;
    }
    
    .bannerA .text {
        font-family: 'Lato';
        font-size: 14px;
        text-align: left;
        display: block;
    }
    
    .bannerA .button {
        font-size: 13px;
        position: relative;
        float: right;
        right: 10px;
        bottom: 10px;
        display: block;
    }

    .bannerB {
        height: 151px;
        border: 2px solid #FBFBFB;
    }
    
    .bannerB img {
        height: 128px;
        align-self: flex-end;
        margin: 0 0 0 5px;
    }
    
    .bannerB .text {
        font-family: 'Arial';
        font-size: 13px;
        text-align: left;
        line-height: 16px;
        align-self: flex-end;
        margin-bottom: 10px;
    }
    
    .bannerB .boo {
        position: absolute;
        font-size: 10vm;
        top: 0;
        right: 16px;
    }

    .bannerC img {
        height: 53px;
    }

    .bannerC .text h1 {
        font-size: 10px !important;
    }
    
    .bannerC .text p {
        font-size: 15px !important;
    }

    .bannerC .btn {
        padding: 3px 8px;
        border-radius: 3px;
        font-size: 9px;
    }
}