.container {
    font-family: 'Lato';
    font-size: 30px;
    font-weight: bold;
    text-align: center;

    display: flex;
    flex-direction: column;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    min-height: 100%;
    max-height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    margin-top: -7rem;
}

.contentContainer {
    width: 817px;
    height: 545px;
    margin: 0 auto;
    padding: 40px 60px;
    border-radius: 16px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
    /* background-image: linear-gradient(129deg, var(--neutral-colors-white), #f8f9fa 96%); */
    background-color: var(--neutral-colors-white);
}

.content {
    display: flex;
}
.content img {
    display: block;
    margin: 0 auto;
    max-height: 300px;
}

.notesHolder{
    flex: 50%;
    padding: 60px 20px;
}
.notesBox{
    background-color: blanchedalmond;
    padding: 10px;
    border-radius: 10px;
    min-height: 180px;
    background: rgb(223,223,223);
    background: -moz-linear-gradient(0deg, rgba(223,223,223,0) 0%, rgba(221,221,221,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(223,223,223,0) 0%, rgba(221,221,221,1) 100%);
    background: linear-gradient(0deg, rgba(223,223,223,0) 0%, rgba(221,221,221,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dfdfdf",endColorstr="#dddddd",GradientType=1); 
}
.notes{
    padding: 10px;
    font-size: 18px;
    color: #000;
}
:global(.fadeOut).notes{
    transition-duration: 4s;
    transition-delay: 2s;
    opacity: 0;
}

@media only screen and (max-width: 575px) {
    .container {
        font-size: 17px;
    }
    .title{
        font-size: 20px !important;
        margin: 10px 0 5vh;
    }
    .contentContainer{
        padding: 20px;
        width: 100vw;
        height: auto;
    }
    .content {
        display: flex;
        flex-direction: column-reverse;
    }
    .content img {
        display: block;
        margin: 0 auto;
        max-height: 250px;
        object-fit: contain;
    }
    .notesHolder{
        flex: 50%;
        padding: 20px 5px 0;
    }
    .notesBox{
        min-height: 150px;
    }
    .notes{
        font-size: 16px !important;
        padding-bottom: 0;
        margin-bottom: 0;
    }
}

/* @media only screen and (orientation: landscape) {
    .container img {
        max-height: 60vh;
    }
}

@media only screen and (orientation: portrait) {
    .container img {
        max-height: 80vw;
    }
} */

@media only screen and (max-width: 820px) {
  .contentContainer{
      box-shadow: none;
  }
  .content img {
    /* width: 100%; */
}
.title{
    /* font-size: 24px !important; */
}
  }