.switcherSpinner {
    position: absolute;
    z-index: 100;
    background-color: rgba(255,255,255,.9);
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  :global(.content) .wrapperNoPadding{
    padding-top: 0 !important;
  }
  :global(.noContact .feedbacklab) {
    display: none !important;
  }
  .finishedBox{
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .finalMsg{
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    line-height: 1.2;
    padding: 20px 10px;
    /* border-top: 2px solid #fa6400; */
    border-bottom: 2px solid #fa6400;
    width: 90%;
  }
  .finalRateBox{
    margin-top: 20px;
    padding: 0px 20px;
    font-size: 20px;
    text-align: center;
  }
  .finalBtnBox{
    width: 100%;
  display: flex;
  justify-content: space-around;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 30px 0;
  padding: 0 20px;
  gap: 20px
}

:global(.mobile) .finalBtnBox {
  flex-wrap: wrap;
  position: relative;
  margin-top: 30px;
}
  /* Modal */
.modal {
  z-index: 999;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
}

.modalCover {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.modalContainer {
  position: relative;
  z-index: 1;
  min-width: 480px;
  min-height: 300px;
  padding: 20px 50px 10px;
  border-radius: 32px;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.25);
  background-image: linear-gradient(132deg, var(--neutral-colors-white), #f8f9fa 98%);
  margin-top: -30vh;
}

.modalContainer .btnHolder {
  margin-top: 60px;
}

:global(.mobile) .modalContainer {
  width: 100%;
  border-radius: 32px 32px 0 0;
  position: fixed;
  bottom: 0;
  height: auto;
  padding: 20px 0;
  min-width: auto;
  font-size: 20px;
  padding: 38px;
  text-align: center;
}

:global(.mobile) .modalContainer > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modalContentBtn {
  width: 106px;
  height: 100px;
  /* padding: 30px; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 24px;
  border: 0;
  /* box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(144deg, var(--neutral-colors-white), #f8f9fa 101%); */
}

.modalContentBtn div {
  width: 100%;
}

.audioPlayer.modalContentBtn {
}

.audioPlayer.modalContentBtn img {
  width: 40px !important;
  height: auto;
  object-fit: contain;
  margin: 0;
}

.audioPlayer.modalContentBtn > div {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 130px;
}

.audioPlayer.modalContentBtn div {
  display: flex !important;
  position: relative !important;
  justify-content: center !important;
}

.audioPlayer.modalContentBtn div img {
  margin: 6px 0 0 !important;
  height: 30px !important;
}

.audioPlayer.modalContentBtn span {
  display: block;
  width: 50%;
  text-align: center;
  font-size: 12px;
  margin: auto;
}

.modalFooter {
  width: 100%;
  display: flex;
  justify-content: space-around;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 30px 0;
  padding: 0 20px;
}

:global(.mobile) .modalFooter {
  flex-wrap: wrap;
  position: relative;
  margin-top: 30px;
}

:global(.mobile) .modalFooter button {
  margin: 7px;
}
.modalCloseBtn{
  width: 25px;
  height: 25px;
  position: absolute;
  right: 5%;
  top: 5%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalCloseBtn:after,
.modalCloseBtn:before{
  content: "";
  display: block;
  position: absolute;
  width: 1px;
  height: 20px;
  background-color: #333;
  transform: rotate(45deg);
}
.modalCloseBtn:before{
  transform: rotate(-45deg);
}
/* ./Modal */
/* Welcome Note */
.welcomeNote{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  padding: 20% 30px 15%;
  z-index: 1000;
  background: rgb(255,233,219);
  background: -moz-linear-gradient(30deg, rgba(255,233,219,1) 0%, rgba(255,255,255,1) 100%);
  background: -webkit-linear-gradient(30deg, rgba(255,233,219,1) 0%, rgba(255,255,255,1) 100%);
  background: linear-gradient(30deg, rgba(255,233,219,1) 0%, rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffe9db",endColorstr="#ffffff",GradientType=1);  
}
.topCircleOne{
  border: 1px dashed #fa6400;
  width: 40vw;
  height: 40vw;
  border-radius: 100%;
  position: fixed;
  top: -23vw;
  left: -16vw;
}
.topCircleTwo{
  border: 1px dashed #fa6400;
  width: 100vw;
  height: 100vw;
  border-radius: 100%;
  position: fixed;
  bottom: -45vw;
  right: -25vw;
}
.topCircleThree{
  border: 1px dashed #fa6400;
  width: 10vw;
  height: 10vw;
  border-radius: 100%;
  position: fixed;
  top: 65vh;
  left: 16vw;
  opacity: .5;
}
.wnHeader{
  margin: 0 auto 30px;
  padding-bottom: 0px;
  border-bottom: 1px solid #fa6400;
  /* width: 100%; */
}
.wnHeader h2{
  text-align: center;
  font-weight: 600;
  color: #333333;
  font-size: 18px !important;
}
.wnHeader :global(.highlight){
  color: #fa6400;
}
.wnContent p{
  color: #1a1a1a;
  border: 1px solid #858585;
  padding: 5px 15px;
  border-width: 0 0px;
  text-align: left;
}
.wnContent .wnNotice:before{
  content:'';
  display: block;
  margin: 0 auto 15px;
  width: 100px;
  height: 100px;
  background: url(../../../assets/images/mic.svg) center no-repeat;
  opacity: .7;
}
.wnNotice{
  display: block;
  border: 1px solid #cabb31;
  background-color: #ffeecd;
  padding: 15px;
  border-radius: 10px;
  margin: 30px auto;
  color: #2b2600;
  text-align: center;
  width: 90%;
}
.wnFooter{
  margin-top: auto;
}
.wnFooter button{
  z-index: 10;
  position: relative;
}
/* ./Welcome Note */
:global(.mobile .mobilRecordBtnHint) {
  display: block;
}