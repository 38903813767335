.container {
  max-width: 817px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 820px) {
  .container {
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 0 !important;
  }
  .container :global(.lesson-dropdwn) {
    border-radius: 0 !important;
    box-shadow: 0 0 0 !important;
  }
}
