.card {
  background-color: unset !important;
  border: unset !important;
}

.card :global(.card-header).collapse::after,
:global(.card-header).collapsed:after {
  font-family: 'FontAwesome';
  content: '\f106';
  font-size: 30px;
  float: right;
  position: absolute;
  top: 9px;
  right: 10px;
  color: var(--chatter-green);
  background-color: #fff;
  border-radius: 60px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  padding: 0 0 5px 0;
}

.card :global(.card-header).collapsed:after {
  content: '\f107';
  padding: 0;
}

.card :global(.card-header) {
  background-color: var(--chatter-green);
  border-radius: 100px !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  color: var(--neutral-colors-white);
  display: flex;
  flex-direction: column;
  min-height: 48px;
  padding: 12px 0 12px 35px;
}

.card :global(.card-title) {
  font-size: 20px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 80%;
  white-space: nowrap;
}
:global(.mobile) .card :global(.card-title) {
  font-size: 18px;
  font-weight: 500;
}

.card :global(.card-subtitle) {
  font-size: 16px;
  font-weight: 400;
  color: var(--neutral-colors-white);
  margin-top: 0px;
}

.card :global(.card-header) :global(.card-title) {
  margin-bottom: 0;
}

.cardBody {
  position: relative;
  padding: 15px 0 !important;
  /* padding: 0 0 15px; */
  display: flex;
  flex-wrap: wrap;
  /* gap: 20px; */
}

.disabled .cardBody {
  z-index: -1;
  filter: grayscale(1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabled .cardBody div {
  opacity: 0.8;
}

.disabled.withNote .cardBody div {
  opacity: 0.3;
}

.disabled .notice {
  position: absolute;
  opacity: 1 !important;
  font-size: 15px;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 16px;
  margin: auto;
  display: block;
  max-width: 280px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.card :global(.row) {
  margin-left: unset;
  margin-right: unset;
  gap: 20px;
  position: relative;
}

:global(.list-view) {
  flex: 0 0 100%;
  max-width: 100%;
}

:global(.grid-view) {
  flex: 0 0 30%;
  max-width: 30%;
}
.backLine {
  position: absolute;
  background-color: var(--neutral-colors-gray-10);
  width: 6px;
  height: 99%;
  left: 22px;
  top: -30px;
  z-index: -1;
}
.noTitle .backLine {
  display: none !important;
}

.lessonsHeader {
  display: flex;
}

.lessonsHeader :global(.card-title) {
  margin-left: 17px;
  font-size: 18px;
  font-weight: 500;
  color: var(--neutral-colors-black);
}

.noTitle :global(.card-header) {
  display: none !important;
}
@media only screen and (max-width: 650px) {
  .cfModuleGroup :global(.card-title) {
    font-size: 16px;
  }
  .cfModuleGroup :global(.card-subtitle) {
    font-size: 14px;
    font-weight: 300;
  }
}
/* @media only screen and (min-width: 650px) and (max-width: 800px) {
  .cfModuleGroup :global(.card-header.collapse::after) {
      font-size: 35px;
      top: 1px;
      right: 20px;
  }

  .cfModuleGroup  :global(.card-title) {
      font-size: 3vw;
  }
  
  .cfModuleGroup :global(.card-subtitle) {
      font-size: 2vw;
      margin-top: 10px;
      margin-bottom: 15px;
  }
} */

/* @media only screen and (max-width: 650px) {
  .cfModuleGroup :global(.card-header.collapse::after) {
      font-size: 24px;
      top: 8px;
      right: 13px;
  }

  .cfModuleGroup  :global(.card-title) {
      font-size: 20px;
  }
  
  .cfModuleGroup :global(.card-subtitle) {
      font-size: 15px;
      margin-top: 10px;
      margin-bottom: 15px;
  }
} */
